<template lang="pug">
    nav(v-if="meta.total > meta.per_page")
        ul.pagination
            li.page-item(v-if="meta.current_page > 1")
                a.page-link(href="#" @click.prevent="back()") &laquo;
            li.page-item( v-for="page in meta.last_page")
                a.page-link(href="#" @click.prevent="switched(page)") {{ page }}
            li.page-item(v-if="meta.current_page < meta.last_page")
                a.page-link(href="#" @click.prevent="next()") &raquo;
</template>
<script>
    export default {
        props: ['meta'],
        methods: {
            back(){
                this.$router.replace({
                    query: {
                        page: this.meta.current_page - 1
                    }
                })
            },
            switched(page){
                this.$router.replace({
                    query: {
                        page: page
                    }
                })
            },
            next(){
                this.$router.replace({
                    query: {
                        page: this.meta.current_page + 1
                    }
                })
            },
        }
    }
</script>