<template lang="pug">
    AdminLayout
        template(slot="sidebar")
            sidebar
        template(slot="adminbody")
            admin-body
                template(slot="title")
                    page-title-component(title="Opticas del Norte" subtitle="Tienda en línea" icon="fa fa-glasses" :withActions="false")
                template(slot="content")
                    div.mb-3.card
                        div.card-header-tab.card-header
                            div.card-header-title.font-size-lg.text-capitalize.font-weight-normal
                                i.header-icon.lnr-charts.icon-gradient.bg-happy-green
                                template Desempeño
                            div.btn-actions-pane-right.text-capitalize
                                //- button.btn-wide.btn-outline-2x.mr-md-2.btn.btn-outline-focus.btn-sm View All
                        div.no-gutters.row
                            div.col-sm-6.col-md-4.col-xl-4
                                div.card.no-shadow.rm-border.bg-transparent.widget-chart.text-left
                                    div.icon-wrapper.rounded-circle
                                        div.icon-wrapper-bg.opacity-10.bg-warning
                                        i.fa.fa-laptop.text-dark.opacity-8
                                    div.widget-chart-content
                                        div.widget-subheading Órdenes
                                        div.widget-numbers {{ dashboard.orders.current }}
                                        div.widget-description.opacity-8.text-focus
                                            div.d-inline.pr-1(:class="dashboard.orders.diff < 0 ? 'text-danger' : 'text-success' ")
                                                i.fa.fa-angle-down
                                                span.pl-1 {{ dashboard.orders.diff.toFixed(0) }}%
                                            template {{ dashboard.orders.diff < 0 ? 'menos órdenes' : 'más órdenes' }}
                                div.divider.m-0.d-md-none.d-sm-block
                            //- div.col-sm-6.col-md-4.col-xl-4
                            //-     div.card.no-shadow.rm-border.bg-transparent.widget-chart.text-left
                            //-         div.icon-wrapper.rounded-circle
                            //-             div.icon-wrapper-bg.opacity-9.bg-danger
                            //-             i.fa.fa-graduation-cap.text-white
                            //-         div.widget-chart-content
                            //-             div.widget-subheading Invested Dividents
                            //-             div.widget-numbers
                            //-                 span 9M
                            //-             div.widget-description.opacity-8.text-focus Grow Rate:
                            //-                 span.text-info.pl-1
                            //-                     i.fa.fa-angle-down
                            //-                     span.pl-1 14.1%
                            //-     div.divider.m-0.d-md-none.d-sm-block
                            div.col-sm-12.col-md-4.col-xl-4
                                div.card.no-shadow.rm-border.bg-transparent.widget-chart.text-left
                                    div.icon-wrapper.rounded-circle
                                        div.icon-wrapper-bg.opacity-9.bg-success
                                        i.fa.fa-city.text-white
                                    div.widget-chart-content
                                        div.widget-subheading Ventas
                                        div.widget-numbers(:class="dashboard.sales.diff > 0 ? 'text-success' : 'text-danger' ")
                                            span  {{ (dashboard.sales.current/100) | currency }}
                                        div.widget-description.text-focus {{ dashboard.sales.diff > 0 ? 'Aumento de' : 'Decremento de' }}
                                            span.pl-1(:class="dashboard.sales.diff > 0 ? 'text-success' : 'text-danger' ")
                                                i.fa(:class="dashboard.sales.diff > 0 ? 'fa-angle-up' : 'fa-angle-down'")
                                                span.pl-1 {{ dashboard.sales.diff }}%
                        div.text-center.d-block.p-3.card-footer
                            //- button.btn-pill.btn-shadow.btn-wide.fsize-1.btn.btn-primary.btn-lg
                            //-     span.mr-2.opacity-7
                            //-         i.icon.icon-anim-pulse.ion-ios-analytics-outline
                            //-     span.mr-1 View Complete Report
</template>

<script>
    import AdminLayout from './../../layouts/main'
    import AdminBody from './../../components/Architecht/Components/body'
    import PageTitleComponent from './../../components/Architecht/Components/bodyComponents/pageTitle'
    import Sidebar from './components/sidebar'
    export default {
        components: {
            AdminLayout,
            AdminBody,
            PageTitleComponent,
            Sidebar
        },
        data(){
            return {
                dashboard: {
                    orders: {
                        current: 0,
                        diff: 0
                    },
                    sales: {
                        current: 0,
                        diff: 0
                    }
                }
            }
        },
        mounted(){
            this.axios.get('dashboard').then(response => {
                this.dashboard = response.data
            })
        }
    }
</script>
<style scoped>

</style>
