<template lang="pug">
    .card.col-sm-12.col-md-4.col-lg-3
        img.card-img-top(:src="`${baseURL}storage${image.url}`")
        .card-body
            .float-right
                a.fa.fa-star.text-primary(v-if="image.default" v-tooltip="'Imagen principal'")
                a.far.fa-star(v-else href="#" @click.prevent="setDefault" v-tooltip="'Establecer como principal'")
                a.fa.fa-trash.text-danger(href="#" @click.prevent="destroy" v-tooltip="'Eliminar imagen'")
</template>
<script>
export default {
    props: {
        index: {
            required: true,
            type: Number
        },
        image: {
            required: true,
            type: Object
        },
    },
    data(){
        return {
            baseURL: window.baseImageURL
        }
    },
    methods: {
        destroy(){
            this.axios.delete(`products-images/${this.image.id}`).then(response => {
                this.$emit('deleteImage', this.index)
            })
        },
        setDefault(){
            this.axios.patch(`products-images/${this.image.id}`).then(response => {
                this.$emit('updateImages')
            })
        }
    }
}
</script>