<template lang="pug">
    form(action="#" @submit.prevent="store")
        .row
            .col-sm-8
                .field
                    div.card-wrapper
                div.row
                    div.col-sm-12.mt-3
                        div.form-group
                            label Número de tarjeta
                            input#cardnumber.form-control(v-model="card.number" name="cardnumber" type="text" required autofocus placeholder="Número de tarjeta" minlength="16" maxlength="22")
                    div.col-sm-3
                        div.form-group
                            label Mes de expiración
                            input#exp_month.form-control(v-model="card.exp_month" name="exp_month" type="number" length="2" required placeholder="MM")
                    div.col-sm-3
                        div.form-group
                            label Año de expiración
                            input#exp_year.form-control(v-model="card.exp_year" name="exp_year" type="number" length="4" required placeholder="YYYY")
                    div.col-sm-6
                        div.form-group
                            label CVC
                            input#cardcvc.form-control(v-model="card.cvc" name="cardcvc" type="password" required placeholder="Código de seguridad")
                    div.col-sm-12
                        div.form-group
                            label Nombre del titular
                            input#cardname.form-control(v-model="card.name" name="cardname" type="text" required placeholder="Nombre del Titular (Como aparece en la tarjeta)" )
                    div.col-sm-12
                        div.form-group
                            label Dirección
                            input#address1.form-control(v-model="card.address.street1" name="address1" type="text" required placeholder="Dirección línea 1" )
                    div.col-sm-12
                        div.form-group
                            input#address2.form-control(v-model="card.address.street2" name="address1" type="text" required placeholder="Dirección línea 2" )
                    div.col-sm-6
                        div.form-group
                            label Estado
                            input#state.form-control(v-model="card.address.state" name="state" type="text" required placeholder="Estado" )
                                
                    div.col-sm-6
                        div.form-group
                            label Ciudad
                            input#city.form-control(v-model="card.address.city" name="city" type="text" required placeholder="Ciudad" )
                                
                    div.col-sm-6
                        div.form-group
                            label Código Postal
                            input#pc.form-control(v-model="card.address.zip" name="pc" type="text" required placeholder="Código Postal" )       
                    //- Stripe card
                    //- #card-element
                    div.col-sm-12
                        button.btn.btn-success(:disabled="storing") Guardar tarjeta
                        a.btn.btn-link.is-text(href="#" @click.prevent="$emit('cancel')") Cancel
</template>
<script>
    export default {
        data(){
            return {
                card: {
                    "number": "",
                    "name": "",
                    "exp_year": "",
                    "exp_month": "",
                    "cvc": "",
                    "address": {
                        "street1": "",
                        "street2": "",
                        "city": "",
                        "state": "",
                        "zip": "",
                        "country": ""
                    }
                },
                conekta: null,
                stripe: null,
                storing: false
            }
        },
        methods: {
            errorResponseHandler(error){
                this.showMessage('Error', 'Ocurrió un error con el guardado de tu tarjeta, revisa los datos por favor.', 'error', '')
            },
            async successResponseHandler(token){
                this.card.last_four = this.card.number[this.card.number.length - 4] 
                    + this.card.number[this.card.number.length - 3] 
                    + this.card.number[this.card.number.length - 2] 
                    + this.card.number[this.card.number.length - 1]

                await this.axios.post('payment-methods', {
                        token: token,
                        card: this.card  
                    }).then(response => {
                        this.showMessage('Éxito', 'Tarjeta guardada con éxito', 'success', '')
                        this.$emit('added', response.data.data)
                    })
            },
            async store(){
                this.storing = true
                // const {token, error} = await this.stripe.createToken(this.card)
                let tokenParams = { card: this.card }
                this.card.brand = Conekta.card.getBrand(this.card.number)
                await Conekta.Token.create(tokenParams, this.successResponseHandler, this.errorResponseHandler);
                this.storing = false
            }
        },
        mounted(){
            const conekta = Conekta.setPublicKey("key_Ebs63Zrqbt7jry7cg4N83Pw");
            // const conekta = Conekta.setPublicKey("key_LuLTU3YEQpZ2bphmGCrfxrg");
            // const stripe = Stripe('pk_test_xhLKcQFsQ3RwXzXwjpZg1chj00AgwF149B')
            // this.stripe = stripe
            Conekta.setLanguage("es");
            Conekta.getPublicKey();
            
            // Stripe card init
            
            // this.card = this.stripe.elements().create('card')
            // this.card.mount('#card-element', {
            //     style: {
            //         base: {
            //             fontSize: '16px'
            //         }
            //     }
            // })
        }
    }
</script>

<style scoped>
    input[type="date"],
    input[type="email"],
    input[type="number"],
    input[type="password"],
    input[type="search"],
    input[type="tel"],
    input[type="text"],
    input[type="url"],
    select,
    textarea{
        margin: 10px auto;
        width: 80%;
    }
    .align-center{
        text-align: center;
    }
</style>