<template lang="pug">
    MainLayout
        template(slot="body")
            div#Subheader
                div.container
                    div.column.one
                        h1.title Registro de nuevo usuario
            section.login_box_area.section_gap
                div.container
                    div.row
                        div.col-lg-12.align-center
                            form.row.login_form(action=""  @submit.prevent="register" id="contactForm")
                                div.col-md-12.form-group
                                    input.form-control(type="text" id="name" name="name" placeholder="Nombre" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Nombre'" v-model="form.name" required)
                                div.col-md-12.form-group
                                    input.form-control(type="email" id="email" name="email" placeholder="Email" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Email'" v-model="form.email" required)
                                div.col-md-12.form-group
                                    input.form-control(type="text" id="phone" name="phone" placeholder="Teléfono" onfocus="this.placeholder = ''" onblur="this.placeholder = 'phone'" v-model="form.phone" required)
                                div.col-md-12.form-group
                                    input.form-control(type="password" id="password" name="password" placeholder="Password" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Password'" v-model="form.password" required)
                                div.col-md-12.form-group
                                    input.form-control(type="password" id="password_confirmation" name="password_confirmation" placeholder="Confirmar Password" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Confirmar Password'" v-model="form.password_confirmation" required)
                                div.col-md-12.form-group
                                    button.primary-btn(type="submit" value="submit") Completar registro
</template>
<script>
	import MainLayout from './../layouts/ecommerce'
    import { mapActions, mapGetters } from 'vuex'
    export default {
        components: {
            MainLayout,
        },
        middleware: [
            'redirectIfAuthenticated'
        ],
        data(){
            return {
                form: {
                    email: '',
                    password: ''
                }
            }
        },
        methods: {
            ...mapActions({
                setAuth: 'auth/setAuth'
            }),
            async register(){
                await this.axios.post('auth/register', this.form).then(response => {

                    window.localStorage.setItem('auth', JSON.stringify(response.data))
                    this.setAuth(response.data)

                    if(this.$route.query.redirect){
                        this.$router.replace(this.$route.query.redirect)
                        return 
                    }
                    
                    this.$router.replace({
                        name: 'index'
                    })
                })
            },
        }
    }
</script>
<style scoped>
    input[type="date"],
    input[type="email"],
    input[type="number"],
    input[type="password"],
    input[type="search"],
    input[type="tel"],
    input[type="text"],
    input[type="url"],
    select,
    textarea{
        margin: 10px auto;
        width: 80%;
    }
    .align-center{
        text-align: center;
    }
</style>