<template lang="pug">
    .table-responsive
        table.table
            thead
                tr
                    th.text-center(scope="col") Imagen
                    th.text-center(scope="col") Producto
                    th.text-center(scope="col") Precio
                    th.text-center(scope="col") Cantidad
                    th.text-center(scope="col") Total
                    th
            tbody
                CartOverviewProduct(v-for="product in products" :key="product.id" :product="product")
                tr
                    td 
                    td 
                    td 
                    td
                        p.has-text-weight-bold Subtotal
                    td
                        p {{ subtotal }}
                    td
                slot(name="rows")
</template>
<script>
import { mapGetters } from 'vuex'
import CartOverviewProduct from '@/components/cart/CartOverviewProduct'
export default {
    components: {
        CartOverviewProduct
    },
    computed: {
        ...mapGetters({
            products: 'cart/products',
            subtotal: 'cart/subtotal'
        })
    }
}
</script>