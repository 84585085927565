<template lang="pug">
    MainLayout
        template(slot="body")
            div#Subheader
                div.container
                    div.column.one
                        h1.title Carrito de compras
            div#Content.woocommerce
                div.content_wrapper.clearfix
                    div.sections_group
                        div.section
                            div.section_wrapper.clearfix
                                div.items_group.clearfix
                                    div.column.one.woocommerce-content
                                        CartOverview(v-if="products.length")
                                            template(slot="rows")
                                                tr
                                                    td 
                                                    td 
                                                    td(colspan="4")
                                                        div.checkout_btn_inner.d-flex.align-items-center
                                                            router-link.btn.btn-link(:to="{name: 'index'}") Continuar comprando
                                                            router-link.btn.btn-primary(:to="{ name:'checkout'}" v-if="!empty") Proceder con pedido
                                        p(v-else) Tu carrito esta vacío
</template>
<script>
    import { mapGetters } from 'vuex'
    import MainLayout from '@/layouts/ecommerce'
    import CartOverview from '@/components/cart/CartOverview'
    export default {
        components: {
            CartOverview,
            MainLayout
        },
        computed: {
            ...mapGetters({
                changed: 'cart/changed',
                empty: 'cart/empty',
                products: 'cart/products'
            })
        }
    }
</script>