<template lang="pug">
    main-layout
        template(slot="sidebar")
            sidebar
        template(slot="adminbody")
            admin-body
                template(slot="title")
                    page-title-component(title="Categorías" subtitle="Catálogo de Categorias" icon="fa fa-globe-americas" :withActions="false")
                template(slot="buttons")
                    li.nav-item
                        a.nav-link.active(role="tab" type="button" @click.prevent="openModal")
                            span Agregar
                    li.nav-item
                        button.nav-link.active(role="tab" type="button" @click="getCategories")
                            span Refrescar
                template(slot="content")
                    div.mb-3.card
                        //- div.card-header-tab.card-header
                            //- div.card-header-title.font-size-lg.text-capitalize.font-weight-normal
                            //-     i.header-icon.lnr-charts.icon-gradient.bg-happy-green
                            //-     template Categorías
                        div.no-gutters.row
                            div.col-sm-12.pt-4
                                div#table.col-xs-12.table-responsive.table-bordered.table-hover.table-striped
                                    table-component(:columns="columns" :data="categories"  @filter="filterData" :meta="meta" :params="params")
                                        template(v-slot="{ row }")
                                            tr
                                                td {{ row.name }}
                                                td {{ row.parent ? row.parent.name : 'Ninguno' }}
                                                td.text-center
                                                    a.fa.fa-trash.text-danger.mr-2(v-if="!row.products" @click.prevent="deleteCategory(row)" v-tooltip="'Eliminar categoria'")
                                                    a.fa.fa-power-off.mr-2(v-if="row.products" @click.prevent="deactivate(row)" :class="!row.active ? 'text-danger' : 'text-primary'" v-tooltip="'Desactivar categoria'")
                                            tr(v-for="child in row.children" :key="child.id")
                                                td {{ child.name }}
                                                td {{ row.name }}
                                                td.text-center
                                                    a.fa.fa-trash.text-danger.mr-2(v-if="!child.products" @click.prevent="deleteCategory(child)" v-tooltip="'Eliminar categoria'")
                                                    a.fa.fa-power-off.mr-2(v-if="child.products" @click.prevent="deactivate(child)" :class="!child.active ? 'text-danger' : 'text-primary'" v-tooltip="'Desactivar categoria'")
                        div.text-center.d-block.p-3.card-footer
        template(slot="modal")
            modal-component(title="Nueva categoría" :editMode="editMode" :model="model" :apiUrl="apiUrl")
                template(slot="modalContent")
                    div.position-relative.form-group
                        validate(tag="div")
                            label(for="name") Nombre
                            input.form-control(name="name" id="name" type="text" v-model="model.name" required)
                            field-messages.text-danger(name="name" show="$invalid && $submitted")
                                div(slot="required") Este campo es requerido
                    div.position-relative.form-group
                        label(for="slug") URL
                        input.form-control(name="slug" id="slug" type="text" v-model="model.slug" disabled)
                    div.position-relative.form-group
                        validate(tag="div")
                            label(for="sub") Es subcategoría
                            input.ml-2(name="sub" id="sub" type="checkbox" v-model="model.sub")
                    div.position-relative.form-group(v-if="model.sub")
                        validate(tag="div")
                            label(for="parent_id") Categoría superior
                            v-select.mr-2(:options="parents" label="name" v-model="model.parent_id" :reduce="name => name.id")
        template(slot="right_bar")
            div.position-relative.form-group
                label(for="code") Clave
                input.form-control(name="code" id="code" type="text" v-model="params.code")
            div.position-relative.form-group
                label(for="name") Nombre
                input.form-control(name="filter_name" id="filter_name" type="text" v-model="params.name")
            div.position-relative.form-group
                button.btn.btn-primary.mr-1(type="button" @click="getCategories") Aplicar
                button.btn.btn-danger(type="button" @click="clearFilter") Quitar
</template>

<script>
    import MainLayout from './../../../layouts/main'
    import AdminBody from './../../../components/Architecht/Components/body'
    import PageTitleComponent from './../../../components/Architecht/Components/bodyComponents/pageTitle'
    import ModalComponent from './../../../components/Architecht/Components/Modals/ModalComponent'
    import Sidebar from './../components/sidebar'
    import vSelect from 'vue-select'
    import 'vue-select/dist/vue-select.css'
    export default {
        components: {
            AdminBody,
            MainLayout,
            PageTitleComponent,
            ModalComponent,
            vSelect,
            Sidebar,
        },
        data() {
            return{
                apiUrl: '/categories',
                categories: [],
                columns: [
                    { label: 'Nombre de categoría', field: 'name', sortable: true },
                    { label: 'Padre', field: 'price', sortable: true },
                    { label: 'Acciones', field: '', sortable: true },
                ],
                editMode: false,
                formstate: {},
                model: {},
                meta: {
                    last_page: 1
                },
                params: {},
                parents: [],
            }
        },
        watch: {
            'model.name' (name){
                this.model.slug = name.split(' ').join('-').toLowerCase()
            },
            async 'model.sub' (value){
                if(value){
                    let response = await this.axios.get(`categories`).then(response => {
                        this.parents = response.data.data
                    })
                }
            },
        },
        methods: {
            deleteCategory(category){
                Swal.fire({
                    title: 'Cuidado!',
                    text: 'Estas seguro de realizar esta acción',
                    icon: 'warning',
                    confirmButtonText: 'Si, vamos a hacerlo',
                    showCancelButton: true,
                    confirmButtonColor: '#ff0000'
                }).then((result) => {
                    if (result.value) {
                        this.axios.delete(`categories/${category.id}`)
                            Swal.fire(
                                'Eliminada!',
                                'La categoría fue eliminada.',
                                'success'
                            )
                    }
                })
            },
            deactivate(category){
                if(category.active){
                    Swal.fire({
                        title: 'Cuidado!',
                        text: 'Estas seguro de realizar esta acción',
                        icon: 'warning',
                        confirmButtonText: 'Si, vamos a hacerlo',
                        showCancelButton: true,
                        confirmButtonColor: '#ff0000'
                    }).then((result) => {
                        if (result.value) {
                            this.setActive(category)
                            Swal.fire(
                                'Desactivada!',
                                'La categoría fue desactivada.',
                                'success'
                            )
                            this.getCategories()
                        }
                    })
                }else{
                    this.setActive(category)
                    Swal.fire(
                        'Activada!',
                        'La categoría fue activada.',
                        'success'
                    )
                }
            },
            async filterData(e){
                this.params = {
                    ...e
                }
                await this.getCategories()
            },

            async clearFilter() {
                this.params = {}
                await this.getCategories()
            },
            openModal() {
                this.editMode = false
                this.apiUrl = '/categories'
                this.parents = this.categories
                $("#modalComponent").modal('show')
            },
            setActive(category){
                category.active = !category.active
                this.axios.patch(`categories/${category.id}`, category)
            },
            async getCategories(){
                await this.axios.get(`categories`, { params: this.params }).then(response => {
                    this.categories = response.data.data
                })
            },
        },
        async mounted() {
            this.openLoading('Espere por favor...')
            await this.getCategories()
            this.stopLoading()

            Fire.$on('AfterChangeData', async () => {
                this.openLoading('Espere por favor...')
                this.model = { name: '' }
                await this.getCategories()
                this.stopLoading()
            })
        },
        beforeDestroy() {
            Fire.$off('AfterChangeData')
        }
    }
</script>
