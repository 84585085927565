<template lang="pug">
    div.app-container.app-theme-white.body-tabs-shadow.fixed-header.fixed-sidebar
        admin-header
        .app-main
            slot(name="sidebar")
            slot(name="adminbody")
        admin-rightbar
            template(slot="content")
                slot(name="right_bar")
        slot(name="modal")
</template>

<script>
    import AdminHeader from './../Architecht/Components/header'
    import AdminRightbar from './../Architecht/Components/rightbar'
    import AdminSidebar from './../Architecht/Components/sidebar'

    export default {
        components: {
            AdminHeader,
            AdminRightbar,
            AdminSidebar,
        },

        data() {
            return {
                showingNavigationDropdown: false,
            }
        },

        methods: {
            switchToTeam(team) {
                this.$inertia.put(route('current-team.update'), {
                    'team_id': team.id
                }, {
                    preserveState: false
                })
            },

            logout() {
                axios.post(route('logout').url()).then(response => {
                    window.location = '/';
                })
            },
        },
        mounted() {
            setTimeout(function(){
                const script = document.createElement('script')
                    script.src = '/js/main.js'
                    document.body.appendChild(script)
            }, 1000)
        },

        computed: {
            path() {
                return window.location.pathname
            }
        }
    }
</script>

<style src="@/assets/admin/css/main.css"></style>
<style>
    button{
        border: none !important;
    }
</style>