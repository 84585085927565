<template lang="pug">
    MainLayout
        template(slot="body")
            div#Subheader
                div.container
                    div.column.one
                        h1.title {{ slug }}
            div#Content.woocommerce
                div.content_wrapper.clearfix
                    div.sections_group
                        div.section
                            div.section_wrapper.clearfix
                                div.items_group.clearfix
                                    div.column.one.woocommerce-content
                                        header.woocommerce-products-header
                                        div.shop-filters
                                            div.woocommerce-notices-wrapper
                                            p.woocommerce-result-count Mostrando {{ products.length }} resultados
                                            form.woocommerce-ordering(method="get")
                                                select.orderby(name="orderby" aria-label="Shop order")
                                                    option(value="menu_order"  selected='selected') Orden por defecto
                                                    option(value="date") Ordenar más reciente
                                                    option(value="price") Ordenar por precio: bajo a alto
                                                    option(value="price-desc") Ordenar por precio: alto a bajo
                                                input(type="hidden" name="paged" value="1")
                                        div.products_wrapper.isotope_wrapper
                                            ul.products.grid
                                                ProductThumb(v-for="product in products" :product="product" :key="product.id")
                                        div
                                            Pagination( :meta="meta")
</template>
<script>
	import MainLayout from './../layouts/ecommerce'
	import ProductThumb from '@/components/products/ProductThumb'
	import Pagination from '@/components/paginator'
    export default {
        name: 'Category',
        components: {
            MainLayout,
            Pagination,
            ProductThumb
        },
        props: {
            slug: {
                required: true
            }
        },
        data(){
            return {
                products: [],
                meta: {}
            }
        },
        methods: {
            getProducts(){
                this.axios.get(`products?category=${this.slug}`).then(response => {
                    this.products = response.data.data
                    this.meta = response.data.meta
                })
            }
        },
        mounted(){
            this.getProducts()
        }
    }
</script>