<template lang="pug">
    .form-group
        label Categorias
        .row
            .col-sm-10
                select.form-control.form-control-line(v-model="category")
                    option(value="null") Selecciona una categoria
                    option(:key="category.id" :value="category" v-for="category in categories") {{ category.name }} {{ category.parent ? '(' + category.parent.name + ')' : '' }}
            .col-sm-2
                .btn.btn-link(@click.prevent="addCategory") Agregar
</template>
<script>
export default {
    data(){
        return {
            category: null,
        }
    },
    props: {
        categories: {
            required: true,
            type: Array
        }
    },
    methods: {
        addCategory(){
            if(this.category){
                this.$emit('input', this.category)
                this.category = {}
            }
        }
    }
}
</script>