<template lang="pug">
    select(@change="changed")
        option Por favor selecciona uno
        option(v-for="country in countries" :value="country.id") {{ country.name }}
</template>
<script>
    export default {
        data(){
            return {
                countries: []
            }
        },
        methods: {
            changed($event){
                this.$emit('input', $event.target.value)
            },
            async getCountries(){
                await this.axios.get('countries').then(response => {
                    this.countries = response.data.data
                })

            }
        },

        created(){
            this.getCountries()
        }
    }
</script>