<template lang="pug">
    main-layout
        template(slot="sidebar")
            sidebar
        template(slot="adminbody")
            admin-body
                template(slot="title")
                    page-title-component(title="Orden" :subtitle="'Detalle de orden ' + order.id " icon="fa fa-shopping-cart" :withActions="false")
                template(slot="buttons")
                    li.nav-item
                        button.nav-link.active(@click.prevent="openModal" v-if="order.status != 'pending' && order.status != 'payment_failed' && order.status != 'completed'")
                            span Agregar Guía
                template(slot="content")
                    div.mb-3.card
                        div.card-header-tab.card-header
                            div.card-header-title.font-size-lg.text-capitalize.font-weight-normal
                                i.header-icon.lnr-charts.icon-gradient.bg-happy-green
                                template Orden
                        div.no-gutters.row
                            .row
                                .col-sm-6
                                    .form-group
                                        strong Cliente: 
                                        span {{ order.user.name }}
                                    .form-group
                                        strong Total orden: 
                                        span {{ order.total }}
                                    .form-group
                                        strong Estatus: 
                                        span {{ order.status }}
                                .col-sm-6
                                    .form-group
                                        strong Dirección: 
                                        span {{ order.address }}
                                    .form-group
                                        strong Método de envío: 
                                        span {{ order.shippingMethod.name }}
                            .row
                                .col-sm-12
                                    ul.body-tabs.body-tabs-layout.tabs-animated.body-tabs-animated.nav
                                        li.nav-item
                                            a.nav-link.active#tab-0(role="tab" data-toggle="tab" href="#tab-content-0")
                                                span Productos
                                        li.nav-item
                                            a.nav-link#tab-2(role="tab" data-toggle="tab" href="#tab-content-1")
                                                span Guías
                                        li.nav-item(v-if="order.status == 'sent'")
                                            a.nav-link#tab-3(@click.prevent="completeOrder")
                                                span Marcar como entregado
                                .card.col-sm-12
                                    .card-block
                                        div.tab-content
                                            div.tab-pane.tabs-animation.fade.show.active#tab-content-0(role="tabpanel")
                                                .table-responsive
                                                    table.table
                                                        thead
                                                            tr
                                                                th.text-center #
                                                                th.text-center Producto
                                                                th.text-center Variación
                                                                th.text-center Cantidad
                                                        tbody
                                                            tr(v-for="(product, index) in order.products" :key="product.id")
                                                                td.text-center {{ index + 1 }}
                                                                td.text-center {{ product.product.name }}
                                                                td.text-center {{ product.name }}
                                                                td.text-center {{ product.pivot.quantity }}
                                            div.tab-pane.tabs-animation.fade#tab-content-1(role="tabpanel")
                                                .table-responsive(v-if="order.guides.length")
                                                    table.table
                                                        thead
                                                            tr
                                                                th.text-center #
                                                                th.text-center Referencia
                                                        tbody
                                                            tr(v-for="(guide, index) in order.guides" :key="guide.id")
                                                                td.text-center {{ index + 1 }}
                                                                td.text-center {{ guide.reference }}
                                                .table-responsive(v-if="!order.guides.length")
                                                   table.table
                                                        thead
                                                            tr
                                                                th.text-center No hay guias cargadas
        template(slot="modal")
            modal-component(title="Nueva guía" :editMode="editMode" :model="model" :apiUrl="apiUrl" @success="getOrder")
                template(slot="modalContent")
                    div.position-relative.form-group
                        validate(tag="div")
                            label(for="reference") Referencia
                            input.form-control(name="reference" id="reference" type="text" v-model="model.reference" required)
                            field-messages.text-danger(name="reference" show="$invalid && $submitted")
                                div(slot="required") Este campo es requerido

</template>
<script>
    import MainLayout from '@/layouts/main'
    import AdminBody from '@/components/Architecht/Components/body'
    import PageTitleComponent from '@/components/Architecht/Components/bodyComponents/pageTitle'
    import ModalComponent from '@/components/Architecht/Components/Modals/ModalComponent'
    import Sidebar from '@/pages/admin/components/sidebar'
    import vSelect from 'vue-select'
    import 'vue-select/dist/vue-select.css'
    import { mapActions } from 'vuex'
    export default {
        name: 'OrderDetailAdmin',
        middleware: [
            'redirectIfGuest',
            'redirectIfNotAdmin'
        ],
        data() {
            return {
                order: {
                    guides: [{}],
                    products: [{
                        name: '',
                        product: {},
                        pivot: {},
                    }],
                    shippingMethod: {
                        name: ''
                    },
                    user: {
                        name: ''
                    },
                },
                editMode: false,
                apiUrl: '',
                form: {
                    variation: '',
                    quantity: 1
                },
                model: {}
            }
        },
        props: {
            id: {
                required: true
            }
        },
        methods: {
            ...mapActions({
                store: 'cart/store'
            }),
            async getOrder(){
                await this.axios.get(`admin-orders/${this.id}`).then(response => {
                    this.order = response.data.data
                    this.$forceUpdate()
                })
            },
            async completeOrder(){
                await this.axios.post(`complete/${this.id}`).then(response => {
                    this.order = response.data.data
                    this.showMessage('Exito', 'Orden marcada correctamente', 'success', '/admin/orders', 1, '', 1)
                    
                    this.$forceUpdate()
                })
            },
            openModal() {
                this.editMode = false
                this.apiUrl = '/guides'
                this.model.order_id = this.order.id
                $("#modalComponent").modal('show')
            },
        },
        watch: {
            'form.variation' (){
                this.form.quantity = 1
            }
        },
        async mounted() {
            this.getOrder();

            Fire.$on('AfterChangeData', async () => {
                this.openLoading('Espere por favor...')
                this.model = { name: '' }
                await this.getOrder()
                this.stopLoading()
            })
        },
        components: {
            AdminBody,
            MainLayout,
            PageTitleComponent,
            ModalComponent,
            vSelect,
            Sidebar,
        }
    }
</script>