<template lang="pug">
    form(action="#" @submit.prevent="store")
        .row
            .col-sm-8
                .field
                    label Nombre
                    input.input(type="text" v-model="form.name")
                .field
                    label Dirección
                    .control
                        input.input(type="text" v-model="form.address_1")
                .field
                    label Ciudad
                    .control
                        input.input(type="text" v-model="form.city")
                .row
                    .col-sm-6
                        .field
                            label Código postal
                            .control
                                input.input(type="text" v-model="form.postal_code")
                    .col-sm-6
                        .field
                            label País
                            .control
                                .select.is-fullwidth
                                    CountryDropdown(v-model="form.country_id")
            .col-sm-4
                .field
                    .control
                        button.button.btn-success(@click.prevent="store") Agregar dirección
                        button.button.is-text(@click.prevent="$emit('cancel')") Cancelar
</template>
<script>
    import CountryDropdown from '@/components/form/CountryDropdown'
    export default {
        components: {
            CountryDropdown
        },
        data() {
            return {
                form: {
                    name: '',
                    address_1: '',
                    postal_code: '',
                    city: '',
                    country_id: '',
                    default: true
                }
            }
        },
        methods: {
            async store(){
                let response = await this.axios.post('addresses', this.form)

                this.$emit('creating', response.data.data)
            }
        }
    }
</script>
<style scoped>
    input[type="date"],
    input[type="email"],
    input[type="number"],
    input[type="password"],
    input[type="search"],
    input[type="tel"],
    input[type="text"],
    input[type="url"],
    select,
    textarea{
        width: 100%;
    }
    .align-center{
        text-align: center;
    }
</style>