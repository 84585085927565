<template lang="pug">
    main-layout
        template(slot="sidebar")
            sidebar
        template(slot="body")
            admin-body
                template(slot="title")
                    page-title-component(title="Catálogos generales" subtitle="Catálogos generales del sistema" icon="fa fa-car")
                template(slot="buttons")
                    li.nav-item
                        a.nav-link.active(role="tab" href="index-2.html")
                            span Variation 1
                    li.nav-item
                        a.nav-link(role="tab" href="analytics-variation.html")
                            span Variation 2
                template(slot="content")
                    div.mb-3.card
                        div.card-header-tab.card-header
                            div.card-header-title.font-size-lg.text-capitalize.font-weight-normal
                                i.header-icon.lnr-charts.icon-gradient.bg-happy-green
                                template Portfolio Performance
                            div.btn-actions-pane-right.text-capitalize
                                button.btn-wide.btn-outline-2x.mr-md-2.btn.btn-outline-focus.btn-sm View All
                        div.no-gutters.row
                            div.col-sm-6.col-md-4.col-xl-4
                                div.card.no-shadow.rm-border.bg-transparent.widget-chart.text-left
                                    div.icon-wrapper.rounded-circle
                                        div.icon-wrapper-bg.opacity-10.bg-warning
                                        i.fa.fa-laptop.text-dark.opacity-8
                                    div.widget-chart-content
                                        div.widget-subheading Cash Deposits
                                        div.widget-numbers 1,7M
                                        div.widget-description.opacity-8.text-focus
                                            div.d-inline.text-danger.pr-1
                                                i.fa.fa-angle-down
                                                span.pl-1 54.1%
                                            template less earnings
                                div.divider.m-0.d-md-none.d-sm-block
                            div.col-sm-6.col-md-4.col-xl-4
                                div.card.no-shadow.rm-border.bg-transparent.widget-chart.text-left
                                    div.icon-wrapper.rounded-circle
                                        div.icon-wrapper-bg.opacity-9.bg-danger
                                        i.fa.fa-graduation-cap.text-white
                                    div.widget-chart-content
                                        div.widget-subheading Invested Dividents
                                        div.widget-numbers
                                            span 9M
                                        div.widget-description.opacity-8.text-focus Grow Rate:
                                            span.text-info.pl-1
                                                i.fa.fa-angle-down
                                                span.pl-1 14.1%
                                div.divider.m-0.d-md-none.d-sm-block
                            div.col-sm-12.col-md-4.col-xl-4
                                div.card.no-shadow.rm-border.bg-transparent.widget-chart.text-left
                                    div.icon-wrapper.rounded-circle
                                        div.icon-wrapper-bg.opacity-9.bg-success
                                        i.fa.fa-city.text-white
                                    div.widget-chart-content
                                        div.widget-subheading Capital Gains
                                        div.widget-numbers.text-success
                                            span $563
                                        div.widget-description.text-focus Increased by
                                            span.text-warning.pl-1
                                                i.fa.fa-angle-up
                                                span.pl-1 7.35%
                        div.text-center.d-block.p-3.card-footer
                            button.btn-pill.btn-shadow.btn-wide.fsize-1.btn.btn-primary.btn-lg
                                span.mr-2.opacity-7
                                    i.icon.icon-anim-pulse.ion-ios-analytics-outline
                                span.mr-1 View Complete Report
</template>

<script>
    import MainLayout from './../../components/Layouts/MainLayout'
    import AdminBody from './../../components/Architecht/Components/body'
    import PageTitleComponent from './../../components/Architecht/Components/bodyComponents/pageTitle'
    import Sidebar from './components/sidebar'
    export default {
        components: {
            AdminBody,
            MainLayout,
            PageTitleComponent,
            Sidebar
        },
    }
</script>
