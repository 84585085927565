<template lang="pug">
    MainLayout
        template(slot="body")
            div#Subheader
                div.container
                    div.column.one
                        h1.title Tus órdenes previas
            div#Content.woocommerce
                div.content_wrapper.clearfix
                    div.sections_group
                        div.section
                            div.section_wrapper.clearfix
                                div.items_group.clearfix
                                    div.column.one.woocommerce-content
                                        .message
                                            .message-body
                                                table.table.table-striped(v-if="orders.length")
                                                    thead
                                                        tr
                                                            th #
                                                            th Fecha
                                                            th Productos
                                                            th Precio
                                                            th Estatus
                                                    tbody
                                                        Order(v-for="order in orders" :order="order" :key="order.id")
                                                p(v-else) No tienes ordenes

</template>
<script>
    import Order from '@/components/orders/Order'
    import MainLayout from '@/layouts/ecommerce'
    import vSelect from 'vue-select'
    import 'vue-select/dist/vue-select.css'
    export default {
        middleware: [
            'redirectIfGuest'
        ],
        components: {
            MainLayout,
            Order,
        },
        data() {
            return {
                orders: []
            }
        },
        async mounted() {
            await this.axios.get('orders').then(response => {
                this.orders = response.data.data
            })
        }
    }
</script>