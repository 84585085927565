<template lang="pug">
    MainLayout
        template(slot="body")
            div#Subheader
                div.container
                    div.column.one
                        h1.title Inicio de sesión
            section.login_box_area.section_gap
                div.container
                    div.row
                        div.col-lg-6
                            div.login_box_img
                                img.img-fluid(src="/img/login.jpg" alt="")
                                div.hover
                                    h4 ¿Nuevo en nuestra tienda?
                                    p Regístrate y conoce todos los productos que puedes adquirir con nosotros, solo da click en el siguiente botón
                                    router-link.primary-btn(:to="{ name: 'register' }") Crear una cuenta
                        div.col-lg-6
                            div.login_form_inner.align-center
                                h3 Iniciar sesión
                                form.row.login_form(action=""  @submit.prevent="signin" id="contactForm" novalidate="novalidate")
                                    div.col-md-12.form-group
                                        input.form-control(type="email" id="email" name="email" placeholder="Email" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Email'" v-model="form.email")
                                    div.col-md-12.form-group
                                        input.form-control(type="password" id="password" name="password" placeholder="Password" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Password'" v-model="form.password")
                                    div.col-md-12.form-group
                                        button.primary-btn(type="submit" value="submit") Log In
                                        //- <a href="#">Forgot Password?</a>
</template>
<script>
	import MainLayout from './../layouts/ecommerce'
    import { mapActions, mapGetters } from 'vuex'
    export default {
        components: {
            MainLayout
        },
        middleware: [
            'redirectIfAuthenticated'
        ],
        data(){
            return {
                form: {
                    email: '',
                    password: ''
                }
            }
        },
        methods: {
            ...mapActions({
                setAuth: 'auth/setAuth'
            }),
            async signin(){
                await this.axios.post('auth/login', this.form).then(response => {
                    window.localStorage.setItem('auth', JSON.stringify(response.data))
                    this.setAuth(response.data)
                })

                if(this.$route.query.redirect){
                    this.$router.replace(this.$route.query.redirect)
                    return 
                }
                
                this.$router.replace({
                    name: 'index'
                })
            },
        }
    }
</script>
<style scoped>
    input[type="date"],
    input[type="email"],
    input[type="number"],
    input[type="password"],
    input[type="search"],
    input[type="tel"],
    input[type="text"],
    input[type="url"],
    select,
    textarea{
        margin: 10px auto;
        width: 80%;
    }
    .align-center{
        text-align: center;
    }
</style>