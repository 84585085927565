<template lang="pug">
    admin-layout
        template(slot="sidebar")
            sidebar
        template(slot="adminbody")
            admin-body
                template(slot="title")
                    page-title-component(title="Inventario de producto" subtitle="Administración de inventario" icon="fa fa-car" :withActions="false")
                template(slot="buttons")
                    li.nav-item
                        router-link.nav-link(role="tab" :to="{name: 'add-stocks', slug: slug }")
                            span Agregar
                template(slot="content")
                    div.mb-3.card
                        div.card-header-tab.card-header
                            div.card-header-title.font-size-lg.text-capitalize.font-weight-normal
                                i.header-icon.lnr-charts.icon-gradient.bg-happy-green.fas.fa-box
                                template Revisión de inventario
                        div.card-body
                            div.no-gutters.row
                                div.col-sm-12
                                    div#table.col-xs-12.table-responsive.table-bordered.table-hover.table-striped
                                        table-component(:columns="columns" :data="stocks"  @filter="filterData" :meta="meta" :params="params")
                                            template(v-slot="{ row }")
                                                tr
                                                    td.text-center {{ row.batch }}
                                                    td.text-center {{ row.quantity }}
</template>

<script>
    import AdminLayout from '@/layouts/main'
    import AdminBody from '@/components/Architecht/Components/body'
    import PageTitleComponent from '@/components/Architecht/Components/bodyComponents/pageTitle'
    import ModalComponent from '@/components/Architecht/Components/Modals/ModalComponent'
    import Sidebar from './../../components/sidebar'
    import vSelect from 'vue-select'
    import 'vue-select/dist/vue-select.css'
    export default {
        name: 'StocksAdmin',
        props: {
            id: {
                requiered: true
            },
            slug: {
                requiered: true
            }
        },
        components: {
            AdminBody,
            AdminLayout,
            PageTitleComponent,
            ModalComponent,
            vSelect,
            Sidebar,
        },
        data() {
            return{
                editMode: false,
                categories: [],
                products: [],
                formstate: {},
                model: {
                    code: '',
                    name: '',
                    alternate_code: '',
                    country_id: '',
                },
                columns: [
                    { label: 'Lote', field: 'name', sortable: true },
                    { label: 'Cantidad', field: 'price', sortable: true },
                ],
                meta: {
                    last_page: 1
                },
                params: {},
                stocks: [],
                apiUrl: '/api/states'
            }
        },
        methods: {
            openModal() {
                this.editMode = false
                this.apiUrl = '/api/states'
                $("#modalComponent").modal('show')
            },
            async getCategories(){
                await this.axios.get(`categories`, { params: this.params }).then(response => {
                    this.categories = response.data.data
                })
            },
            async getStocks() {
                await this.axios.get(`show-stocks/${this.id}`, { params: this.params })
                .then( response => {
                    this.stocks = response.data.data
                    this.meta = response.data.meta
                    this.stopLoading()
                })
                .catch( error => {
                    this.catchError(error)
                })
            },

            async filterData(e){
                this.params = {
                    ...e
                }
                await this.getStocks()
            },

            async clearFilter() {
                this.params = {}
                await this.getStocks()
            },
        },
        async mounted() {
            this.openLoading('Espere por favor...')
            await this.getStocks()
            await this.getCategories()

            Fire.$on('AfterChangeData', async () => {
                this.openLoading('Espere por favor...')
                await this.getStocks()
                this.stopLoading()
            })
        },
        beforeDestroy() {
            Fire.$off('AfterChangeData')
        }
    }
</script>
