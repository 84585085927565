<template lang="pug">
    admin-layout
        template(slot="sidebar")
            sidebar
        template(slot="adminbody")
            admin-body
                template(slot="title")
                    page-title-component(title="Productos" subtitle="Catálogo de productos" icon="fa fa-home" :withActions="false")
                template(slot="content")
                    div.mb-3.card
                        div.card-header-tab.card-header
                            div.card-header-title.font-size-lg.text-capitalize.font-weight-normal
                                i.header-icon.lnr-charts.icon-gradient.bg-happy-green.fas.fa-box
                                template Creación de producto
                        div.card-body
                            div.no-gutters.row
                                form.form-horizontal.form-material.p-3
                                    .row
                                        .col-md-3.col-sm-12
                                            label Variación producto
                                            v-select(:options="variations" label="name" :reduce="name => name.id"  v-model="form.product.variation")
                                        .col-md-4.col-sm-12
                                            label Lote
                                            input.form-control(label="batch" placeholder="Lote" v-model="form.product.batch")
                                        .col-md-3.col-sm-12
                                            .form-group
                                                .form-group
                                                    label Cantidad a ingresar
                                                    input.form-control.form-control-line(type="number" placeholder="Cantidad" v-model="form.product.quantity")
                                        .col-md-2.col-sm-12
                                            button.float-right.btn.btn-link.align-text-bottom(@click.prevent="addBatch") Agregar Lote
                                        .col-sm-12
                                            table.table.table-striped(v-if="form.products.length")
                                                thead.thead-light
                                                    tr
                                                        th.text-center Variación
                                                        th.text-center Lote
                                                        th.text-center Cantidad
                                                        th 
                                                tbody
                                                    tr(v-for="(product, index) in form.products" :key="product.batch")
                                                        td.text-center {{ findVariation(product.variation).name }}
                                                        td.text-center {{ product.batch }}
                                                        td.text-center {{ product.quantity }}
                                                        td 
                                                            i.fa.fa-trash.text-danger(@click.prevent="removeBatch(index)")
                                        .col-sm-12
                                            button.float-right.btn.btn-success(@click.prevent="store" :disabled="!form.products.length") Guardar
                                            router-link.float-right.btn.btn-link(:to="{ name: 'admin-products-stocks-slug', params: { slug: slug } }") Cancelar
</template>
<script>
    import AdminLayout from '@/layouts/main'
    import CategorySelect from '@/components/products/CategorySelect'
    import AdminBody from '@/components/Architecht/Components/body'
    import PageTitleComponent from '@/components/Architecht/Components/bodyComponents/pageTitle'
    import Sidebar from './../../components/sidebar'
    import vSelect from 'vue-select'
    import 'vue-select/dist/vue-select.css'
    export default {
        props: {
            slug: {
                required: true
            }
        },
        name: 'AddStock',
        layout: 'admin',
        components: {
            CategorySelect,
            AdminLayout,
            AdminBody,
            PageTitleComponent,
            Sidebar,
            vSelect
        },
        middleware: [
            'redirectIfGuest',
            'redirectIfNotAdmin'
        ],
        data() {
            return {
                data: "",
                form: {
                    product: {},
                    products: []
                },
                variationType: {
                    productVariation: null
                },
                variations: [],
            }
        },
        methods: {
            addBatch() {
            this.form.products.push(this.form.product)
            this.form.product = {}
        },
        findVariation(id){
            let variation = this.variations.find(v => v.id == id)
            if (typeof variation === 'undefined'){
                return null
            }
            return variation
        },
        removeBatch(index){
            this.form.products.splice(index, 1)
        },
        store(){
            Swal.fire({
                title: 'Confirmación!',
                text: 'La información es correcta? no se podrá modificar',
                icon: 'warning',
                confirmButtonText: 'Si, vamos a registrarlo',
                showCancelButton: true,
            }).then((result) => {
                if (result.value) {
                    this.axios.post(`stocks`, this.form).then(response => {
                        Swal.fire(
                            'Guardado!',
                            'El inventario se guardó correctamente.',
                            'success'
                        )
                        router.push({to: 'products'})
                    }).catch(errors => {
                        Swal.fire(
                            'Error!',
                            errors.response.data.errors.products[0],
                            'error'
                        )
                    })
                }
            })
        }
        },
        async mounted(){
            this.axios.get(`show-variations/${this.slug}`).then(response => {
               this.variations = response.data.data
            })
        }
    }
</script>