<template lang="pug">
    div.app-sidebar.sidebar-shadow
        div.app-header__logo
            div.logo-src
            div.header__pane.ml-auto
                div
                    button.hamburger.close-sidebar-btn.hamburger--elastic(type="button" data-class="closed-sidebar")
                        span.hamburger-box
                            span.hamburger-inner
        div.app-header__mobile-menu
            div
                button.hamburger.hamburger--elastic.mobile-toggle-nav(type="button")
                    span.hamburger-box
                        span.hamburger-inner
        div.app-header__menu
            span
                button.btn-icon.btn-icon-only.btn.btn-primary.btn-sm.mobile-toggle-header-nav(type="button")
                   span.btn-icon-wrapper
                        i.fa.fa-ellipsis-v.fa-w-6
        div.scrollbar-sidebar
            div.app-sidebar__inner
                ul.vertical-nav-menu
                    li.app-sidebar__heading Menu
                    li.mm-active
                        a(href="#")
                            i.metismenu-icon.fa.fa-rocket
                            template Dashboards
                            i.metismenu-state-icon.fa.fa-angle-down.caret-left
                        ul
                            li
                                a.mm-active(href="index-2.html")
                                    i.metismenu-icon
                                    template Analytics
                            li
                                a(href="dashboards-commerce.html")
                                    i.metismenu-icon
                                    template Commerce
                            li
                                a(href="dashboards-sales.html")
                                    i.metismenu-icon
                                    template Sales
                            li
                                a(href="#")
                                    i.metismenu-icon
                                    template Minimal
                                    i.metismenu-state-icon.fa.fa-angle-down.caret-left
                                ul
                                    li
                                        a(href="dashboards-minimal-1.html")
                                            i.metismenu-icon
                                            template Variation 1
                                    li
                                        a(href="dashboards-minimal-2.html")
                                            i.metismenu-icon
                                            template Variation 2
                            li
                                a(href="dashboards-crm.html")
                                    i.metismenu-icon
                                    template CRM
                    li
                        a(href="#")
                            i.metismenu-icon.fa.fa-window-maximize
                            template Pages
                            i.metismenu-state-icon.fa.fa-angle-down.caret-left
                        ul
                            li
                                a(href="pages-login.html")
                                    i.metismenu-icon
                                    template Login
                            li
                                a(href="pages-login-boxed.html")
                                    i.metismenu-icon
                                    template Login Boxed
                            li
                                a(href="pages-register.html")
                                    i.metismenu-icon
                                    template Register
                            li
                                a(href="pages-register-boxed.html")
                                    i.metismenu-icon
                                    template Register Boxed
                            li
                                a(href="pages-forgot-password.html")
                                    i.metismenu-icon
                                    template Forgot Password
                            li
                                a(href="pages-forgot-password-boxed.html")
                                    i.metismenu-icon
                                    template Forgot Password Boxed
                    li
                        a(href="#")
                            i.metismenu-icon.fa.fa-box
                            template Applications
                            i.metismenu-state-icon.fa.fa-angle-down.caret-left
                        ul
                            li
                                a(href="apps-mailbox.html")
                                    i.metismenu-icon
                                    template Mailbox
                            li
                                a(href="apps-chat.html")
                                    i.metismenu-icon
                                    template Chat
                            li
                                a(href="apps-faq-section.html")
                                    i.metismenu-icon
                                    template FAQ Section
                            li
                                a(href="#")
                                    i.metismenu-icon
                                    template Forums
                                    i.metismenu-state-icon.fa.fa-angle-down.caret-left
                                ul
                                    li
                                        a(href="apps-forum-list.html")
                                            i.metismenu-icon
                                            template Forum Listing
                                    li
                                        a(href="apps-forum-threads.html")
                                            i.metismenu-icon
                                            template Forum Threads
                                    li
                                        a(href="apps-forum-discussion.html")
                                            i.metismenu-icon
                                            template Forum Discussion
                    li.app-sidebar__heading UI Components
                    li
                        a(href="#")
                            i.metismenu-icon.fa.fa-gem
                            template  Elements
                            i.metismenu-state-icon.fa.fa-angle-down.caret-left
                        ul
                            li
                                a(href="#")
                                    i.metismenu-icon
                                    template Buttons
                                    i.metismenu-state-icon.fa.fa-angle-down.caret-left
                                ul
                                    li
                                        a(href="elements-buttons-standard.html")
                                            i.metismenu-icon
                                            template Standard
                                    li
                                        a(href="elements-buttons-pills.html")
                                            i.metismenu-icon
                                            template Pills
                                    li
                                        a(href="elements-buttons-square.html")
                                            i.metismenu-icon
                                            template Square
                                    li
                                        a(href="elements-buttons-shadow.html")
                                            i.metismenu-icon
                                            template Shadow
                                    li
                                        a(href="elements-buttons-icons.html")
                                            i.metismenu-icon
                                            template With Icons
                            li
                                a(href="elements-dropdowns.html")
                                    i.metismenu-icon
                                    template Dropdowns
                            li
                                a(href="elements-icons.html")
                                    i.metismenu-icon
                                    template Icons
                            li
                                a(href="elements-badges-labels.html")
                                    i.metismenu-icon
                                    template Badges
                            li
                                a(href="elements-cards.html")
                                    i.metismenu-icon
                                    template Cards
                            li
                                a(href="elements-loaders.html")
                                    i.metismenu-icon
                                    template Loading Indicators
                            li
                                a(href="elements-list-group.html")
                                    i.metismenu-icon
                                    template List Groups
                            li
                                a(href="elements-navigation.html")
                                    i.metismenu-icon
                                    template Navigation Menus
                            li
                                a(href="elements-timelines.html")
                                    i.metismenu-icon
                                    template Timeline
                            li
                                a(href="elements-utilities.html")
                                    i.metismenu-icon
                                    template Utilities
                    li
                        a(href="#")
                            i.metismenu-icon.fa.fa-car
                            template  Components
                            i.metismenu-state-icon.fa.fa-angle-down.caret-left
                        ul
                            li
                                a(href="components-tabs.html")
                                    i.metismenu-icon
                                    template Tabs
                            li
                                a(href="components-accordions.html")
                                    i.metismenu-icon
                                    template Accordions
                            li
                                a(href="components-notifications.html")
                                    i.metismenu-icon
                                    template Notifications
                            li
                                a(href="components-modals.html")
                                    i.metismenu-icon
                                    template Modals
                            li
                                a(href="components-loading-blocks.html")
                                    i.metismenu-icon
                                    template Loading Blockers
                            li
                                a(href="components-progress-bar.html")
                                    i.metismenu-icon
                                    template Progress Bar
                            li
                                a(href="components-tooltips-popovers.html")
                                    i.metismenu-icon
                                    template Tooltips &amp; Popovers
                            li
                                a(href="components-carousel.html")
                                    i.metismenu-icon
                                    template Carousel
                            li
                                a(href="components-calendar.html")
                                    i.metismenu-icon
                                    template Calendar
                            li
                                a(href="components-pagination.html")
                                    i.metismenu-icon
                                    template Pagination
                            li
                                a(href="components-count-up.html")
                                    i.metismenu-icon
                                    template Count Up
                            li
                                a(href="components-scrollable-elements.html")
                                    i.metismenu-icon
                                    template Scrollable
                            li
                                a(href="components-tree-view.html")
                                    i.metismenu-icon
                                    template Tree View
                            li
                                a(href="components-maps.html")
                                    i.metismenu-icon
                                    template Maps
                            li
                                a(href="components-ratings.html")
                                    i.metismenu-icon
                                    template Ratings
                            li
                                a(href="components-image-crop.html")
                                    i.metismenu-icon
                                    template Image Crop
                            li
                                a(href="components-guided-tours.html")
                                    i.metismenu-icon
                                    template Guided Tours
                    li
                        a(href="#")
                            i.metismenu-icon.fa.fa-table
                            template  Tables
                            i.metismenu-state-icon.fa.fa-angle-down.caret-left
                        ul
                            li
                                a(href="tables-data-tables.html")
                                    i.metismenu-icon
                                    template Data Tables
                            li
                                a(href="tables-regular.html")
                                    i.metismenu-icon
                                    template Regular Tables
                            li
                                a(href="tables-grid.html")
                                    i.metismenu-icon
                                    template Grid Tables
                    li.app-sidebar__heading Dashboard Widgets
                    li
                        a(href="widgets-chart-boxes.html")
                            i.metismenu-icon.fa.fa-chart-line
                            template Chart Boxes 1
                    li
                        a(href="widgets-chart-boxes-2.html")
                            i.metismenu-icon.fa.fa-chart-bar
                            template Chart Boxes 2
                    li
                        a(href="widgets-chart-boxes-3.html")
                            i.metismenu-icon.fa.fa-chart-area
                            template Chart Boxes 3
                    li
                        a(href="widgets-profile-boxes.html")
                            i.metismenu-icon.fa.fa-id-card
                            template Profile Boxes
                    li.app-sidebar__heading Forms
                    li
                        a(href="#")
                            i.metismenu-icon.far.fa-lightbulb
                            template Elements
                            i.metismenu-state-icon.fa.fa-angle-down.caret-left
                        ul
                            li
                                a(href="forms-controls.html")
                                    i.metismenu-icon
                                    template Controls
                            li
                                a(href="forms-layouts.html")
                                    i.metismenu-icon
                                    template Layouts
                            li
                                a(href="forms-validation.html")
                                    i.metismenu-icon
                                    template Validation
                            li
                                a(href="forms-wizard.html")
                                    i.metismenu-icon
                                    template Wizard
                    li
                        a(href="#")
                            i.metismenu-icon.fa.fa-gamepad
                            template  Widgets
                            i.metismenu-state-icon.fa.fa-angle-down.caret-left
                        ul
                            li
                                a(href="forms-datepicker.html")
                                    i.metismenu-icon
                                    template Datepicker
                            li
                                a(href="forms-range-slider.html")
                                    i.metismenu-icon
                                    template Range Slider
                            li
                                a(href="forms-input-selects.html")
                                    i.metismenu-icon
                                    template Input Selects
                            li
                                a(href="forms-toggle-switch.html")
                                    i.metismenu-icon
                                    template Toggle Switch
                            li
                                a(href="forms-wysiwyg-editor.html")
                                    i.metismenu-icon
                                    template WYSIWYG Editor
                            li
                                a(href="forms-input-mask.html")
                                    i.metismenu-icon
                                    template Input Mask
                            li
                                a(href="forms-clipboard.html")
                                    i.metismenu-icon
                                    template Clipboard
                            li
                                a(href="forms-textarea-autosize.html")
                                    i.metismenu-icon
                                    template Textarea Autosize
                    li.app-sidebar__heading Charts
                    li
                        a(href="charts-chartjs.html")
                            i.metismenu-icon.fa.fa-chart-line
                            template ChartJS
                    li
                        a(href="charts-apexcharts.html")
                            i.metismenu-icon.fa.fa-chart-bar
                            template Apex Charts
                    li
                        a(href="charts-sparklines.html")
                            i.metismenu-icon.fa.fa-chart-area
                            template Chart Sparklines
</template>
<style lang="stylus">
</style>
<script>
    
    export default {
        components: {
        }
    }
</script>