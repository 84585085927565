<template lang="pug">
    div.app-drawer-wrapper
        div.drawer-nav-btn
            button.hamburger.hamburger--elastic.is-active(type="button")
                span.hamburger-box
                    span.hamburger-inner
        div.drawer-content-wrapper
            div.scrollbar-container
                h3.drawer-heading Filtrar
                div.drawer-section
                    slot(name="content")
</template>
<style lang="stylus">
</style>
<script>
export default {
    components: {
    },
}
</script>