<template lang="pug">
    article.message
        .message-body
            h2.title Enviar a
            .row
                .col-sm-12
                    template(v-if="selecting")
                        ShippingAddressSelector(:addresses="addresses" :selectedAddress="selectedAddress" @click="switchAddress")
                    template(v-else-if="creating")
                        ShippingAddressCreator(@cancel="creating = false" @creating="created")
                    template(v-else)
                        template(v-if="selectedAddress")
                            .row
                                .col-sm-8
                                    strong {{ selectedAddress.name }}
                                    br
                                    span {{ selectedAddress.address_1 }}
                                    br
                                    span {{ selectedAddress.city }}
                                    br
                                    span {{ selectedAddress.postal_code }}
                                    br
                                    span {{ selectedAddress.country.name }}
                                .col-sm-4
                                    p.control
                                        a.gray_btn(href="" @click.prevent="selecting = true" v-if="addresses.length > 1") Cambiar Dirección
                                    p.control
                                        a.primary-btn(href="" @click.prevent="creating = true") Agregar Dirección de Envío
                        template(v-else)
                            .row
                                .col-sm-8
                                    h3 No existen direcciones de envío
                                .col-sm-4
                                    p.control
                                        a.primary-btn(href="" @click.prevent="creating = true") Agregar Dirección de Envío
</template>
<script>
    import ShippingAddressCreator from './ShippingAddressCreator'
    import ShippingAddressSelector from './ShippingAddressSelector'
    export default {
        components:{
            ShippingAddressCreator,
            ShippingAddressSelector,
        },
        data() {
            return {
                creating: false,
                selecting: false,
                localAddress: this.addresses,
                selectedAddress: null
            }
        },
        props: {
            addresses: {
                requiered: true,
                type: Array
            }
        },
        computed: {
            defaultAddress(){
                return this.addresses.find(a => a.default == true)
            }
        },
        watch: {
            selectedAddress(address){
                this.selecting = false
                this.$emit('input', address.id)
            }
        },
        methods: {
            created(address){
                this.addresses.push(address)
                this.creating = false
                this.switchAddress(address)
            },
            switchAddress(address){
                if(this.selectedAddress != address)
                    this.selectedAddress = address
                else
                    this.selecting = false
            }
        },
        mounted(){
            setTimeout(() => {
                if(this.addresses.length){
                    this.switchAddress(this.defaultAddress)
                }
            }, 500)
        }
    }
</script>