<template lang="pug">
    admin-layout
        template(slot="sidebar")
            sidebar
        template(slot="adminbody")
            admin-body
                template(slot="title")
                    page-title-component(title="Productos" subtitle="Catálogo de productos" icon="fa fa-home" :withActions="false")
                template(slot="buttons")
                    li.nav-item(v-if="canUpload")
                        a.nav-link.active(role="tab" type="button" href="#"  @click.prevent="toggleAction")
                            span.btn-icon-wrapper.pr-2
                                i.fa.fa-plus(v-if="!action")
                                i.fa.fa-minus(v-if="action")
                            span(v-if="!action")  Cargar
                            span(v-if="action")  Ocultar
                template(slot="content")
                    div.mb-3.card
                        div.card-header-tab.card-header
                            div.card-header-title.font-size-lg.text-capitalize.font-weight-normal
                                i.header-icon.lnr-charts.icon-gradient.bg-happy-green.fas.fa-box
                                template Administración de imágenes de producto
                        div.card-body
                            b-collapse#uploadFiles.row(v-model="action" v-if="canUpload")
                                .col-sm-12
                                    file-pond(name="test" 
                                        ref="pond" 
                                        label-idle="Arrastra tus archivos aquí o <span class='filepond--label-action'>Buscar</span>." 
                                        :allow-multiple="true" 
                                        :server="`${url}${product.id}/${type}`"
                                        :files="myFiles"
                                        :allowRevert="false"
                                        @processfile="getImages"
                                        :allowImageValidateSize="true"
                                        imageValidateSizeLabelImageSizeTooSmall="La imagen es muy pequeña"
                                        imageValidateSizeLabelImageSizeTooBig="La imagen es muy grande"
                                        imageValidateSizeLabelExpectedMinSize="Las dimensiones minimas son {minWidth} x {minHeight}"
                                        imageValidateSizeLabelExpectedMaxSize="Las dimensiones maximas son {maxWidth} x {maxHeight}"
                                        )
                                        //- //- 
                                        //- :imageValidateSizeMinHeight="620"
                                        //- :imageValidateSizeMinWidth="620"
                                        //- :imageValidateSizeMaxHeight="960"
                                        //- :imageValidateSizeMaxWidth="1280"
                            .row
                                ProductImageAdmin(v-for="(image, index) in product.images" :image="image" :index="index" @deleteImage="removeImage" @updateImages="getImages" :key="image.id")
</template>
<script>
    import CategorySelect from '@/components/products/CategorySelect'
    import AdminLayout from '@/layouts/main'
    import AdminBody from '@/components/Architecht/Components/body'
    import PageTitleComponent from '@/components/Architecht/Components/bodyComponents/pageTitle'
    import Sidebar from './../components/sidebar'
    import ProductImageAdmin from '@/components/products/ProductImageAdmin'
    import vueFilePond from 'vue-filepond';
    import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
    import FilePondPluginImageResize from 'filepond-plugin-image-resize';
    import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';

    const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImageValidateSize)

    export default {
        name: 'ImageAdmin',
        layout: 'admin',
        components: {
            AdminBody,
            AdminLayout,
            CategorySelect,
            FilePond,
            PageTitleComponent,
            ProductImageAdmin,
            Sidebar,
        },
        props: {
            slug: {
                required: true
            }
        },
        middleware: [
            'redirectIfGuest',
            'redirectIfNotAdmin'
        ],
        data() {
            return {
                action: false,
                canUpload: true,
                myFiles: [],
                product: {},
                type: 'main',
                url: window.baseURL + 'products-images/'
            }
        },
        methods: {
             checkQuantity(){
                if(this.product.images.length > 5){
                    this.canUpload = false
                    this.action = false
                }else{
                    this.canUpload = true
                }
            },
            async getImages() {
                await this.axios.get(`products/${this.product.slug}`).then( response => {
                    this.product = response.data.data
                    this.checkQuantity()
                })
            },
            toggleAction(){
                this.action = !this.action
            },
            removeImage(index){
                this.product.images.splice(index, 1)
                this.checkQuantity()
            }
        },
        async mounted(){
            this.axios.get(`products/${this.slug}`).then(response => {
                this.product = response.data.data
                this.checkQuantity()
            })
        }
    }
</script>
<style>
    .filepond--credits{
        display: none !important;
    }
</style>
<style src="@/../node_modules/filepond/dist/filepond.min.css"></style>
<style src="@/../node_modules/filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css"></style>