<template lang="pug">
    table.table.is-hoverable.is-fullwidth
        tbody
            tr(v-for="address in addresses" :key="address.id")
                td 
                     p(:class="{'has-text-weight-bold': address.id === selectedAddress.id}")
                        strong {{ address.name }}
                        br
                        span {{ address.address_1 }}
                        br
                        span {{ address.city }}
                        br
                        span {{ address.postal_code }}
                        br
                        span {{ address.country.name }}
                td
                    a.button.is-info(href="" @click.prevent="$emit('click', address)") Enviar aquí

</template>
<script>
    export default {
        props: {
            addresses: {
                required: true,
                type: Array
            },
            selectedAddress: {
                required: true,
                type: Object
            }
        }
    }
</script>