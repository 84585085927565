<template lang="pug">
    MainLayout
        template(slot="body")
            div#Subheader
                div.container
                    div.column.one
                        h1.title Tienda
            div#Content.woocommerce(v-if="oxxoOrder.id == undefined")
                div.content_wrapper.clearfix
                    div.sections_group
                        div.section
                            .container
                                .returning_customer
                                    ShippingAddress(:addresses="addresses" v-model="form.address_id")
                                article.message(v-if="shippingMethodId")
                                    .message-body
                                        h2.title Forma de pago
                                        select.form-control.p-2(v-model="paymentType" @change="setPaymentType")
                                            option(value="") Por favor selecciona uno
                                            option(value="card") Tarjeta debito/crédito
                                            option(value="paypal") Paypal
                                            option(value="oxxo") OXXO
                                .returning_customer(v-if="paymentType == 'card'")
                                    PaymentMethods(:paymentMethods="paymentMethods" v-model="form.payment_method_id")
                                article.message(v-if="shippingMethodId")
                                    .message-body
                                        h2.title Método de Envío
                                        select.form-control.p-2(v-model="form.shippingMethodId")
                                            option(value="") Por favor selecciona uno
                                            option(:value="shippingMethod.id" v-for="shippingMethod in shippingMethods") {{ shippingMethod.name }} ({{ shippingMethod.price }})
                                article.message.mb-4
                                    .message-body
                                        h2.title Receta de graduación
                                        input(type="file" name="prescription" @change="addFile")
                                .returning_customer.mt-2
                                    article.message(v-if="products.length")
                                        .message-body
                                            h1.title.is-5 Resumen de compra
                                            CartOverview
                                                template(slot="rows")
                                                    tr(v-if="shippingMethodId")
                                                        td
                                                        td
                                                        td
                                                        td.has-text-weight-bold Envío
                                                        td {{ shipping.price }}
                                                        td
                                                    tr
                                                        td
                                                        td
                                                        td
                                                        td.has-text-weight-bold Total
                                                        td {{ total }}
                                                        td
                                    article.message
                                        .message-body
                                            div(v-if="paymentType == 'paypal' && ready")
                                                paypal-checkout(:amount="form.total", currency="MXN", :client="paypal" env="sandbox", :button-style="myStyle" v-on:payment-authorized="paymentAuthorized" v-on:payment-completed="paymentCompleted" v-on:payment-cancelled="paymentCancelled")
                                            div(v-if="paymentType != 'paypal'")
                                                button.btn.float-right(:disabled="empty || submitting" :class="!empty || submitting ? 'primary-btn' : 'btn-text'" @click.prevent="order") Comprar
                                            div(v-if="paymentType == 'paypal' && !ready")
                                                button.btn.float-right(:disabled="empty || submitting" :class="!empty || submitting ? 'primary-btn' : 'btn-text'" @click.prevent="setReady") Listo
            div#order.woocommerce(v-if="oxxoOrder.id != undefined")
                .container(style="text-align:center")
                    .btn.btn-link(@click.prevent="printPage") Imprimir
                    a.btn.btn-link.ml-5(href="/orders") Ir a ordenes
                .opps
                    .opps-header
                        .opps-reminder Ficha digital. No es necesario imprimir.
                        .opps-info
                            .opps-brand
                                img(src="/img/oxxopay_brand.png" alt="OXXOPay")
                            .opps-ammount
                                h3 Monto a pagar
                                h2 {{ oxxoOrder.total }}
                                p OXXO cobrará una comisión adicional al momento de realizar el pago.
                        .opps-reference
                            h3 Referencia
                            h1 {{ oxxoOrder.oxxoOrder.reference }}
                            img(:src="oxxoOrder.oxxoOrder.barcode" style="width:100%; height:50px")
                    .opps-instructions
                        h3 Instrucciones
                        ol
                            li Acude a la tienda OXXO más cercana. 
                                a(href="https://www.google.com.mx/maps/search/oxxo/" target="_blank") Encuéntrala aquí.
                            li Indica en caja que quieres realizar un pago de 
                                strong OXXOPay.
                            li Dicta al cajero el número de referencia en esta ficha para que tecleé directamete en la pantalla de venta.
                            li Realiza el pago correspondiente con dinero en efectivo.
                            li Al confirmar tu pago, el cajero te entregará un comprobante impreso. 
                                strong En el podrás verificar que se haya realizado correctamente.
                                |  Conserva este comprobante de pago.
                        .opps-footnote Al completar estos pasos recibirás un correo de 
                            strong Optica del norte
                            |  confirmando tu pago.
</template>
<script>
	import MainLayout from './../layouts/ecommerce'
    import CartOverview from '@/components/cart/CartOverview'
    import ShippingAddress from '@/components/checkout/addresses/ShippingAddress'
    import PaymentMethods from '@/components/checkout/paymentMethods/PaymentMethods'
    import { mapGetters, mapActions } from 'vuex'
    import PaypalCheckout from 'vue-paypal-checkout'
    export default {
        middleware: [
            'redirectIfGuest'
        ],
        data(){
            return {
                addresses: [],
                data: new FormData(),
                form: {
                    address_id: null,
                    payment_method_id: null,
                    shipping_method_id: ''
                },
				myStyle: {
				  label: 'checkout',
				  size:  'large',
				},
                oxxoOrder: {},
                paypalOrder: {
                    total: 0
                },
                paymentMethods: [],
                paymentType: '',
				paypal: {
					sandbox: "AVy0ZMpIPKkVMlUmg-3mLBVuoS7b8YocwSb_KH4knCTedGusEX4QA8RFEJilvE7xxwi-DeitUmGpiCdd",
					production: 'AVTkbxIXoYlDJdnHpM-qSlbS3TnWoVN7Hh7XGHcsFSCXpjMGEcmPWDpXlCwopu7evBLoiBVgul6c7pbf'
				},
                submitting: false,
                ready: false,
                shippingMethods: [],
            }
        },
        components: {
            CartOverview,
            MainLayout,
            PaypalCheckout,
            PaymentMethods,
            ShippingAddress,
        },
        computed: {
            ...mapGetters({
                empty: 'cart/empty',
                products: 'cart/products',
                total: 'cart/total',
                shipping: 'cart/shipping',
            }),
            shippingMethodId: {
                get(){
                    return this.shipping ? this.shipping.id : ''
                },
                set(shippingMethodId){
                    this.setShipping(
                        this.shippingMethods.find(s => s.id === shippingMethodId)
                    )
                }
            }
        }, 
        methods: {
            ...mapActions({
                setShipping: 'cart/setShipping',
                getCart: 'cart/getCart',
                flash: 'alert/flash'
            }),
            addFile(e){
                this.data = new FormData()
                this.data.append('prescription', e.target.files[0])
            },
            async getShippingMethdosForAddress(address_id){
                await this.axios.get(`addresses/${address_id}/shipping`).then(response => {
                    this.shippingMethods = response.data.data
                    return response.data
                })

            },
            async order() {
                this.submitting = true
                Swal.fire({
                    text: 'Cargando',
                    didOpen(){
                        Swal.showLoading()
                    },
                    allowOutsideClick: false,
                })
                let items = {
                    ...this.form,
                    shipping_method_id: this.shippingMethodId
                }
                for ( var key in items) {
                    this.data.append(key, items[key]);
                }

                await this.axios.post('orders', this.data).then(response => {
                    this.getCart()
                    Swal.close()
                    if(response.data.data.status != 'payment_pending'){
                        window.location.href = '/orders'
                    }else{
                        this.oxxoOrder = response.data.data
                    }
                }).catch(e => {
                    this.getCart()
                    this.submitting = false
                    Swal.close()
                    Swal.fire('Error!', 'Ha ocurrido un error', 'error')
                })
            },

			paymentAuthorized: function (data) {
			  console.log(data);
			},
			paymentCompleted: function (data) {
				this.form.payment_info = data.payer.payer_info
				this.order()
			},
			paymentCancelled: function (data) {
			  console.log(data);
			},
            printPage(){
                window.print();
            },
            setReady(){
                this.ready = true
            },
            setPaymentType(){
                if(this.paymentType == 'oxxo'){
                    this.form.payment_method_id = 1
                }
                if(this.paymentType == 'paypal'){
                    this.form.payment_method_id = 2
                    this.form.total = this.total
                    this.form.total = this.form.total.replace('MXN', '')
                    this.form.total = this.form.total.replace(',', '')
                    this.form.total = Number(this.form.total) 
                    this.form.total = this.form.total.toString()
                }
            }
        },
        watch: {
            'form.address_id' (address_id){
                this.getShippingMethdosForAddress(address_id).then(() => {
                    this.setShipping(this.shippingMethods[0])
                })
            },
            'shippingMethodId' (){
                this.getCart()
            },

            'total' (){
                this.form.total = this.total
                this.form.total = this.form.total.replace('MXN', '')
                this.form.total = this.form.total.replace(',', '')
                this.form.total = Number(this.form.total) 
                this.form.total = this.form.total.toString()
            }
        },
        mounted(){
            const script = document.createElement('script')
            // script.src = 'https://js.stripe.com/v3/'
            script.src = 'https://cdn.conekta.io/js/latest/conekta.js'
            document.body.appendChild(script)
            
            this.axios.get('addresses').then(response => {
                this.addresses = response.data.data
            })
            this.axios.get('payment-methods').then(response => {
                this.paymentMethods = response.data.data
            })
        }
    }
</script>
<style scoped>
    input[type="date"],
    input[type="email"],
    input[type="number"],
    input[type="password"],
    input[type="search"],
    input[type="tel"],
    input[type="text"],
    input[type="url"],
    select,
    textarea{
        margin: 10px auto;
        width: 80%;
    }
    .align-center{
        text-align: center;
    }

    #order h3 {
        margin-bottom: 10px;
        font-size: 15px;
        font-weight: 600;
        text-transform: uppercase;
    }

    #order .opps {
        width: 496px; 
        border-radius: 4px;
        box-sizing: border-box;
        padding: 0 45px;
        margin: 40px auto;
        overflow: hidden;
        border: 1px solid #b0afb5;
        font-family: 'Open Sans', sans-serif;
        color: #4f5365;
    }

    #order .opps-reminder {
        position: relative;
        top: -1px;
        padding: 9px 0 10px;
        font-size: 11px;
        text-transform: uppercase;
        text-align: center;
        color: #ffffff;
        background: #000000;
    }

    #order .opps-info {
        margin-top: 26px;
        position: relative;
    }

    #order .opps-info:after {
        visibility: hidden;
        display: block;
        font-size: 0;
        content: " ";
        clear: both;
        height: 0;

    }

    #order .opps-brand {
        width: 45%;
        float: left;
    }

    #order .opps-brand img {
        max-width: 150px;
        margin-top: 2px;
    }

    #order .opps-ammount {
        width: 55%;
        float: right;
    }

    #order .opps-ammount h2 {
        font-size: 36px;
        color: #000000;
        line-height: 24px;
        margin-bottom: 15px;
    }

    #order .opps-ammount h2 sup {
        font-size: 16px;
        position: relative;
        top: -2px
    }

    #order .opps-ammount p {
        font-size: 10px;
        line-height: 14px;
    }

    #order .opps-reference {
        margin-top: 14px;
    }

    #order h1 {
        font-size: 27px;
        color: #000000;
        text-align: center;
        margin-top: -1px;
        padding: 6px 0 7px;
        border: 1px solid #b0afb5;
        border-radius: 4px;
        background: #f8f9fa;
    }

    #order .opps-instructions {
        margin: 32px -45px 0;
        padding: 32px 45px 45px;
        border-top: 1px solid #b0afb5;
        background: #f8f9fa;
    }

    #order ol {
        margin: 17px 0 0 16px;
    }

    #order li + li {
        margin-top: 10px;
        color: #000000;
    }

    #order a {
        color: #1155cc;
    }

    #order .opps-footnote {
        margin-top: 22px;
        padding: 22px 20 24px;
        color: #108f30;
        text-align: center;
        border: 1px solid #108f30;
        border-radius: 4px;
        background: #ffffff;
    }
</style>

<style scoped src="@/../public/css/global.css"></style>
<style scoped src="@/../public/content/optics/css/structure.css"></style>
<style scoped src="@/../public/content/optics/css/custom.css"></style>
<style scoped src="@/../public/content/optics/css/optics.css"></style>