<template lang="pug">
    admin-layout
        template(slot="sidebar")
            sidebar
        template(slot="adminbody")
            admin-body
                template(slot="title")
                    page-title-component(title="Productos" subtitle="Catálogo de productos" icon="fa fa-home" :withActions="false")
                template(slot="content")
                    div.mb-3.card
                        div.card-header-tab.card-header
                            div.card-header-title.font-size-lg.text-capitalize.font-weight-normal
                                i.header-icon.lnr-charts.icon-gradient.bg-happy-green.fas.fa-box
                                template Creación de producto
                        div.card-body
                            div.no-gutters.row
                                .col-md-6.col-sm-12
                                    .container
                                        .form-group
                                            label Nombre de producto
                                            input.form-control.form-control-line(type="text" placeholder="Nombre de Producto" v-model="form.product.name")
                                        .form-group
                                            label URL producto
                                            input.form-control.form-control-line(type="text" disabled placeholder="nombre-de-producto" v-model="form.product.slug")
                                        .form-group
                                            label Precio base
                                            input.form-control.form-control-line(type="number" placeholder="500.00" step="any" v-model="form.product.price")
                                        .form-group
                                            label Descripción
                                            textarea.form-control.form-control-line(type="type" placeholder="Descripción del producto" v-model="form.product.description")
                                .col-md-6.col-sm-12
                                    .container
                                        CategorySelect(v-model="category" :categories="categories")
                                        .form-group
                                            ul
                                                li(v-for="(category, index) in form.product.categories") {{ category.name }}
                                                    a.fa.fa-trash.ml-3.text-danger(href="#" @click.prevent="removeFromArray(index, form.product.categories)")
                                        .form-group
                                            label Variación de productos
                                            .row
                                                .col-sm-12.mt-2
                                                    select.form-control.form-control-line(v-model="variationType.productVariation")
                                                        option(value="null") Selecciona un tipo
                                                        option(v-for="type in variationTypes" :key="type.id" :value="type") {{ type.name }}
                                                .col-sm-12.mt-2
                                                    input.form-control.form-control-line(v-model="variationType.price" type="number" placeholder="Precio (opcional)")
                                                .col-sm-12.mt-2
                                                    input.form-control.form-control-line(v-model="variationType.name" placeholder="Nombre de variación. ej. Negro, Grande, Mediana")
                                                .col-sm-12.align-right
                                                    .btn.btn-link.float-right(@click.prevent="addVariation") Agregar
                                        .form-group
                                            ul
                                                li(v-for="(type, index) in form.product.variations") {{ type.productVariation.name }} - {{ type.name }}
                                                    span.badge.badge-primary.ml-1(v-if="type.price")  ${{ type.price | currency }}
                                                    a.fa.fa-trash.ml-3.text-danger(href="#" @click.prevent="removeFromArray(index, form.product.variations)")
                                .col-sm-12
                                    button.btn.btn-success.float-right(@click.prevent="store") Guardar
                                    router-link.btn.btn-link.text-danger.float-right(:to="{ name: 'products' }") Cancelar
</template>
<script>
    import AdminLayout from '@/layouts/main'
    import CategorySelect from '@/components/products/CategorySelect'
    import AdminBody from '@/components/Architecht/Components/body'
    import PageTitleComponent from '@/components/Architecht/Components/bodyComponents/pageTitle'
    import Sidebar from './../components/sidebar'
    export default {
        name: 'AddProduct',
        layout: 'admin',
        components: {
            CategorySelect,
            AdminLayout,
            AdminBody,
            PageTitleComponent,
            Sidebar
        },
        middleware: [
            'redirectIfGuest',
            'redirectIfNotAdmin'
        ],
        data() {
            return {
                category: {},
                categories: [],
                data: "",
                form: {
                    product: {
                        categories: [],
                        variations: [],
                    }
                },
                variationType: {
                    productVariation: null
                },
                variationTypes: [],
            }
        },
        watch: {
            'form.product.name' (name){
                this.form.product.slug = name.split(' ').join('-')
            },
            'category' (category){
                if(!this.findCategory(category.id))
                    this.form.product.categories.push(category)
            }
        },
        methods: {
            addCategory(){
                if(!this.form.product.categories){
                    this.form.product.categories = []
                }
                this.form.product.categories.push(this.category)
                this.category = {}
            },
            addVariation(){
                if(!this.form.product.variations){
                    this.form.product.variations = []
                }

                if(!this.findVariation(this.variationType.productVariation.id, this.variationType.name)){
                    this.form.product.variations.push(this.variationType)
                    this.variationType = {}
                }
            },
            findCategory(id){
                let category = this.form.product.categories.find(v => v.id == id)
                if (typeof category === 'undefined'){
                    return null
                }

                return category
            },
            findVariation(id, name){
                let variation = this.form.product.variations.find(v => (v.productVariation.id == id && v.name == name))
                if (typeof variation === 'undefined'){
                    return null
                }

                return variation
            },
            removeFromArray(index, array){
                array.splice(index, 1)
            },
            store(){
                this.axios.post('products', this.form.product).then(response => {
                    this.$router.replace({
                        name: 'products'
                    })
                })
            }
        },
        async mounted(){
            this.axios.get(`all-variations`).then(response => {
               this.variationTypes = response.data
            })
            this.axios.get(`get-categories`).then(response => {
               this.categories = response.data.data
            })
        }
    }
</script>