<template lang="pug">
    main-layout
        template(slot="sidebar")
            sidebar
        template(slot="adminbody")
            admin-body
                template(slot="title")
                    page-title-component(title="Métodos de envío" subtitle="Catálogo de métodos de envío" icon="fa fa-globe-americas" :withActions="false")
                template(slot="buttons")
                    li.nav-item
                        a.nav-link.active(role="tab" type="button" @click.prevent="openModal")
                            span Agregar
                    li.nav-item
                        button.nav-link.active(role="tab" type="button" @click="getMethods")
                            span Refrescar
                template(slot="content")
                    div.mb-3.card
                        //- div.card-header-tab.card-header
                            //- div.card-header-title.font-size-lg.text-capitalize.font-weight-normal
                            //-     i.header-icon.lnr-charts.icon-gradient.bg-happy-green
                            //-     template Métodos de envío
                        div.no-gutters.row
                            div.col-sm-12.pt-4
                                div#table.col-xs-12.table-responsive.table-bordered.table-hover.table-striped
                                    table-component(:columns="columns" :data="methods"  @filter="filterData" :meta="meta" :params="params")
                                        template(v-slot="{ row }")
                                            tr
                                                td {{ row.name }}
                                                td {{ row.price }}
                                                td 
                                                    ul(v-if="row.countries.length") 
                                                        li(v-for="country in row.countries") {{ country.name }}
                                                    span(v-else) Sin paises asignados
                                                td.text-center
                                                    a.fa.fa-edit.mr-2(@click.prevent="edit(row)" v-tooltip="'Desactivar categoria'")
                                                    a.fa.fa-power-off.mr-2.text-danger(@click.prevent="deactivate(row)" v-tooltip="'Desactivar categoria'")
                        div.text-center.d-block.p-3.card-footer
        template(slot="modal")
            modal-component(title="Nueva categoría" :editMode="editMode" :model="model" :apiUrl="apiUrl")
                template(slot="modalContent")
                    div.position-relative.form-group
                        validate(tag="div")
                            label(for="name") Nombre
                            input.form-control(name="name" id="name" type="text" v-model="model.name" required)
                            field-messages.text-danger(name="name" show="$invalid && $submitted")
                                div(slot="required") Este campo es requerido
                    div.position-relative.form-group
                        validate(tag="div")
                            label(for="price") Precio
                            input.form-control(name="price" id="price" type="number" v-model="model.price")
                            field-messages.text-danger(name="name" show="$invalid && $submitted")
                                div(slot="number") Este campo debe de ser numérico
                    div.position-relative.form-group
                        label(for="countries") Paises aplicables
                        v-select(name="countries" id="countries" :options="countries" v-model="model.countries" taggable multiple label="name")
        template(slot="right_bar")
            div.position-relative.form-group
                label(for="code") Clave
                input.form-control(name="code" id="code" type="text" v-model="params.code")
            div.position-relative.form-group
                label(for="name") Nombre
                input.form-control(name="filter_name" id="filter_name" type="text" v-model="params.name")
            div.position-relative.form-group
                button.btn.btn-primary.mr-1(type="button" @click="getMethods") Aplicar
                button.btn.btn-danger(type="button" @click="clearFilter") Quitar
</template>

<script>
    import MainLayout from '@/layouts/main'
    import AdminBody from '@/components/Architecht/Components/body'
    import PageTitleComponent from '@/components/Architecht/Components/bodyComponents/pageTitle'
    import ModalComponent from '@/components/Architecht/Components/Modals/ModalComponent'
    import Sidebar from './../components/sidebar'
    import vSelect from 'vue-select'
    import 'vue-select/dist/vue-select.css'
    export default {
        name: "ShippmentIndex",
        components: {
            AdminBody,
            MainLayout,
            PageTitleComponent,
            ModalComponent,
            vSelect,
            Sidebar,
        },
        data() {
            return{
                apiUrl: '/methods',
                countries: [],
                methods: [],
                columns: [
                    { label: 'Método de envío', field: 'name', sortable: true },
                    { label: 'Precio', field: 'price', sortable: true },
                    { label: 'Paises asignados', field: 'countries', sortable: false },
                    { label: 'Acciones', field: '', sortable: true },
                ],
                editMode: false,
                formstate: {},
                model: {},
                meta: {
                    last_page: 1
                },
                params: {},
                parents: [],
            }
        },
        methods: {
            deactivate(method){
                if(method.status == 'active'){
                    Swal.fire({
                        title: 'Cuidado!',
                        text: 'Estas seguro de realizar esta acción',
                        icon: 'warning',
                        confirmButtonText: 'Si, vamos a hacerlo',
                        showCancelButton: true,
                        confirmButtonColor: '#ff0000'
                    }).then((result) => {
                        if (result.value) {
                            this.axios.delete(`shipping-methods/${this.method.id}`).then(response => {
                                this.methods = response.data.data
                            })
                            Swal.fire(
                                'Desactivada!',
                                'El método fue desactivado.',
                                'success'
                            )
                            this.getMethods()
                        }
                    })
                }else{
                    this.setActive(method)
                    Swal.fire(
                        'Activada!',
                        'El método fue desactivado.',
                        'success'
                    )
                }
            },
            async filterData(e){
                this.params = {
                    ...e
                }
                await this.getMethods()
            },
            async clearFilter() {
                this.params = {}
                await this.getMethods()
            },
            edit(method) {
                this.model = JSON.parse(JSON.stringify(method))
                this.model.price = this.model.price.replace('MXN', '')
                this.model.price = this.model.price.replace(',', '')
                this.model.price = Number(this.model.price)

                this.editMode = true
                this.apiUrl = '/shipping-methods/'
                $("#modalComponent").modal('show')
            },
            openModal() {
                this.editMode = false
                this.apiUrl = '/shipping-methods'
                $("#modalComponent").modal('show')
            },
            async getCountries(){
                await this.axios.get(`countries`, { params: this.params }).then(response => {
                    this.countries = response.data.data
                })
            },
            async getMethods(){
                await this.axios.get(`shipping-methods`, { params: this.params }).then(response => {
                    this.methods = response.data.data
                })
            },
        },
        async mounted() {
            this.openLoading('Espere por favor...')
            await this.getMethods()
            await this.getCountries()
            this.stopLoading()

            Fire.$on('AfterChangeData', async () => {
                this.openLoading('Espere por favor...')
                this.model = { name: '' }
                await this.getMethods()
                this.stopLoading()
            })
        },
        beforeDestroy() {
            Fire.$off('AfterChangeData')
        }
    }
</script>
