<template lang="pug">
    div.dropdown-menu-xl.rm-pointers.dropdown-menu.dropdown-menu-right(tabindex="-1" role="menu" aria-hidden="true")
        div.dropdown-menu-header.mb-0
            div.dropdown-menu-header-inner.bg-deep-blue
                div.menu-header-image.opacity-1(style="background-image: url('/admin/assets/images/dropdown-header/city3.jpg');")
                div.menu-header-content.text-dark
                    h5.menu-header-title Notifications
                    h6.menu-header-subtitle You have 
                        b 21
                        template unread messages
        ul.tabs-animated-shadow.tabs-animated.nav.nav-justified.tabs-shadow-bordered.p-3
            li.nav-item
                a.nav-link.active(role="tab" data-toggle="tab" href="#tab-messages-header")
                    span Messages
            li.nav-item
                a.nav-link(role="tab" data-toggle="tab" href="#tab-events-header")
                    span Events
            li.nav-item
                a.nav-link(role="tab" data-toggle="tab" href="#tab-errors-header")
                    span System Errors
        div.tab-content
            timelinelist-component
            event-list-component
            empty-list-component
        ul.nav.flex-column
            li.nav-item-divider.nav-item
            li.nav-item-btn.text-center.nav-item
                button.btn-shadow.btn-wide.btn-pill.btn.btn-focus.btn-sm View Latest Changes
</template>
<script>
import EmptyListComponent from './notificationsComponents/emptyListComponent'
import EventListComponent from './notificationsComponents/eventListComponent'
import TimelinelistComponent from './notificationsComponents/timelineListComponent'
export default {
    components: {
        EmptyListComponent,
        EventListComponent,
        TimelinelistComponent
    }
}
</script>