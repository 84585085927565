<template lang="pug">
    .field
        label.label {{ type }}:
        select.form-control(:value="selectedVariationId" @change="changed($event, type)")
            option(value="") Elige
            option(v-for="variation in variations" :key="variation.id" :value="variation.id" :disabled="!variation.in_stock") {{ variation.name }}
                template(v-if="variation.price_varies")  ({{ variation.price }})
                template(v-if="!variation.in_stock")  (out of stock)

</template>
<script>
export default {
    props: {
        type: {
            required: true,
            type: String
        },
        variations: {
            required: true,
            type: Array
        },
        value: {
            required: false,
            default: ''
        }
    },
    computed: {
        selectedVariationId(){
            if(!this.findVariation(this.value.id)){
                return ''
            }
            return this.value.id
        }
    },
    methods: {
        changed(e, type){
            this.$emit('input', this.findVariation(event.target.value))
        },
        findVariation(id){
            let variation = this.variations.find(v => v.id == id)
            if (typeof variation === 'undefined'){
                return null
            }

            return variation
        }
    }
}
</script>