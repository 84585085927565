<template lang="pug">
    tr
        td {{ order.id }}
        td {{ order.created_at }}
        td
            div(v-for="variation in products" :key="variation.id")
                router-link(:to="{ name: 'product', params: { slug: variation.product.slug } }") {{ variation.product.name }} ({{ variation.name }}) - {{ variation.type }}
            template(v-if="moreProducts > 0") and {{ moreProducts }} more
        td {{ order.subtotal }}
        td
            component(:is="order.status")
</template>
<script>
    import OrderStatusComplete from '@/components/orders/statuses/OrderStatus-complete'
    import OrderStatusPaymentFailed from '@/components/orders/statuses/OrderStatus-payment_failed'
    import OrderStatusPending from '@/components/orders/statuses/OrderStatus-pending'
    import OrderStatusProcessing from '@/components/orders/statuses/OrderStatus-processing'
    import OrderStatusSent from '@/components/orders/statuses/OrderStatus-sent'
    import OrderStatusPaymentPending from '@/components/orders/statuses/OrderStatus-payment_pending'

    export default {
        components: {
            'complete': OrderStatusComplete,
            'payment_failed': OrderStatusPaymentFailed,
            'processing': OrderStatusProcessing,
            'pending': OrderStatusPending,
            'payment_pending': OrderStatusPaymentPending,
            'sent': OrderStatusSent
        },
        data(){
            return {
                maxProducts: 2,
            }
        },
        props: {
            order: {
                require: true,
                type: Object
            }
        },
        computed: {
            products(){
                return this.order.products.slice(0, this.maxProducts)
            },
            moreProducts(){
                return this.order.products.length - this.maxProducts
            }
        }
    }
</script>