<template lang="pug">
    tr
        td
            div.d-flex.align-items-center.text-center
                img.thumbnail(:src="`//delnorte.dconsultores.com/storage${product.product.image.url}`" v-if="product.product.image.url" alt="")
                img(src="http://via.placeholder.com/60x60" alt="" v-else)
        td
            p {{ product.product.name }} / {{ product.type }} {{ product.name }}
        td(width="160") 
            p {{ product.price }}
        td 
            .field
                .control
                    .select.is-fullwidth
                        select(v-model="quantity")
                            option(value="0" v-if="product.quantity == 0") 0
                            option(vale="" v-for="x in product.stock_count" :key="x" :selected="x == product.quantity") {{ x }}
        td {{ product.total }}
        td 
            a(href="" @click.prevent="destroyProduct(product.id)") Eliminar
</template>
<style>
.thumbnail{
    height: 70px;
    width: 70px;
}
</style>
<script>
    import { mapActions } from 'vuex'
    export default {
        props: {
            product: {
                required: true,
                type: Object
            }
        },
        computed: {
            quantity: {
                get(){
                    return this.product.quantity
                },
                set(quantity){
                    this.update({ product_id: this.product.id, quantity: quantity})
                }
            }
        },
        methods: {
            ...mapActions({
                destroy: 'cart/destroy',
                update: 'cart/update'
            }),

            destroyProduct(product_id){
                if(confirm('Are you sure?')){
                    this.destroy(product_id)
                }
            }
        },

    }
</script>