<template lang="pug">
    MainLayout
        template(slot="body")
            div#Subheader
                div.container
                    div.column.one
                        h1.title Tienda
            div#Content.woocommerce
                div.content_wrapper.clearfix
                    div.sections_group
                        div.section
                            div.section_wrapper.clearfix
                                div.items_group.clearfix
                                    div.column.one.woocommerce-content
                                        div#product-94.share-simple.wide.tabs.product.type-product.post-94.status-publish.first.instock.product_cat-for-her.product_cat-for-him.has-post-thumbnail.shipping-taxable.purchasable.product-type-simple
                                            div.mt-5.product_wrapper.clearfix(v-if="product")
                                                div.column.one-second.product_image_wrapper
                                                    div.woocommerce-product-gallery.woocommerce-product-gallery--with-images.woocommerce-product-gallery--columns-4.images(data-columns="4" style="transition: opacity .25s ease-in-out;")
                                                        figure.woocommerce-product-gallery__wrapper
                                                            div.woocommerce-product-gallery__image(v-for="(image, index) in product.images" :key="index" :data-thumb="'//delnorte.dconsultores.com/storage' + image.url" data-thumb-alt="")
                                                                a(:href="'//delnorte.dconsultores.com/storage' + image.url")
                                                                    img.wp-post-image(width="600" height="444" :src="'//delnorte.dconsultores.com/storage' + image.url" alt="" loading="lazy" :title="product.name" data-caption="" :data-src="'//delnorte.dconsultores.com/storage' + image.url" :data-large_image="'//delnorte.dconsultores.com/storage' + image.url" data-large_image_width="1080" data-large_image_height="800")
                                                div.summary.entry-summary.column.one-second
                                                    h1.product_title.entry-title {{ product.name }}
                                                    //- div.product_meta
                                                    //-     span.posted_in Categories: 
                                                    //-         a(href="for-her.html" rel="tag") FOR HER, 
                                                    //-         a(href="for-him.html" rel="tag") FOR HIM
                                                    p.price
                                                        span.woocommerce-Price-amount.amount
                                                            bdi
                                                                template {{ product.price }}
                                                    div.woocommerce-product-details__short-description
                                                        p {{ product.description }}
                                                        a(href="#") 
                                                            span Disponibilidad:
                                                            template {{ product.in_stock ? ' En existencia' : ' Agotado' }}
                                                    section.section(v-if="auth.loggedIn")
                                                        form(action="" @submit.prevent="add")
                                                            ProductVariation(v-for="(variations, type) in product.variations" 
                                                                :key="type" 
                                                                :type="type" 
                                                                :variations="variations" 
                                                                v-model="form.variation")
                                                            .field.has-addons(v-if="form.variation")
                                                                label(for="quantity") Cantidad:
                                                                select.form-control.p-2(name="quantity" v-model="form.quantity")
                                                                    option(v-for="x in parseInt(form.variation.stock_count)" :key="x" :value="x") {{ x }}
                                                                button.primary-btn.mt-2(type="submit") Agregar a carrito
                                                    section.section(v-else)
                                                        .card_area.d-flex.align-items-center
                                                            router-link.primary-btn.mt-2(:to="{ name: 'auth-register' }") Registrarte
                                                    div.share-simple-wrapper
                                                        span.share-label Compartir
                                                        div.icons
                                                            a.facebook(target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fthemes.muffingroup.com%2Fbe%2Foptics%2Fproduct%2Fduis-id-ante%2F")
                                                                i.fab.fa-facebook
                                                            a.twitter(target="_blank" href='https://twitter.com/intent/tweet?text=" Duis+id+ante+%26%238211%3B+BeOptics+%26%238211%3B+Betheme.+https%3A%2F%2Fthemes.muffingroup.com%2Fbe%2Foptics%2Fproduct%2Fduis-id-ante%2F"')
                                                                i.fab.fa-twiiter
                                                            a.linkedin(target="_blank" href='https://www.linkedin.com/shareArticle?mini=" true&amp;url="https%3A%2F%2Fthemes.muffingroup.com%2Fbe%2Foptics%2Fproduct%2Fduis-id-ante%2F"')
                                                                i.fab.fa-linkedin
                                                            a.pinterest(target="_blank" href='https://pinterest.com/pin/find/?url="https%3A%2F%2Fthemes.muffingroup.com%2Fbe%2Foptics%2Fproduct%2Fduis-id-ante%2F')
                                                                i.fab.fa-pinterest
                                                        div.button-love
                                                            a.mnf-love(href="#" data-id="94")
                                                                span.icons-wrapper
                                                                    i.icon-heart-empty-fa
                                                                    i.icon-heart-fa
                                                                span.label 0

                                        //-     <div class="jq-tabs tabs_wrapper">


                                        //-         <div id="tab-description">
                                        //-             <p>
                                        //-                 <hr class="no_line" style="margin:0 auto 50px" />
                                        //-                 <div class="column one">
                                        //-                     <div class="column one-third">
                                        //-             </p>
                                        //-             <h3>Quantum pertore
                                        //-                 <br> venire por no</h3>
                                        //-             <p>
                                        //-                 Lorem ipsum <strong>dolor sit amet</strong>, consectetur adipiscing elit, sed do eiusmod tempor incididspendisse ultrices gravida. Risus commodo viverra maecen.
                                        //-             </p>
                                        //-             <p>
                                        //-                 </div>
                                        //-                 <div class="column one-third">
                                        //-                     <div class="image_frame image_item no_link scale-with-grid alignnone no_border">
                                        //-                         <div class="image_wrapper"><img class="scale-with-grid" src="/images/optics-single-product-dude.jpg" />
                                        //-                         </div>
                                        //-                     </div>
                                        //-                 </div>
                                        //-                 <div class="column one-third">
                                        //-                     <div class="image_frame image_item no_link scale-with-grid alignnone no_border">
                                        //-                         <div class="image_wrapper"><img class="scale-with-grid" src="/images/optics-single-product-girl.jpg" />
                                        //-                         </div>
                                        //-                     </div>
                                        //-                 </div>
                                        //-                 </div>
                                        //-                 <hr style="margin:0 auto 70px;" />
                                        //-                 <div class="column one">
                                        //-                     <div class="column one-fourth">
                                        //-                         <div class="image_frame image_item no_link scale-with-grid aligncenter no_border">
                                        //-                             <div class="image_wrapper"><img class="scale-with-grid" src="/images/optics-single-priduct-icon-1.png" />
                                        //-                             </div>
                                        //-                         </div>
                                        //-             </p>
                                        //-             <h5 style="text-align:center;">Anti-reflection</h5>
                                        //-             <p>
                                        //-                 </div>
                                        //-                 <div class="column one-fourth">
                                        //-                     <div class="image_frame image_item no_link scale-with-grid aligncenter no_border">
                                        //-                         <div class="image_wrapper"><img class="scale-with-grid" src="/images/optics-single-priduct-icon-3.png" />
                                        //-                         </div>
                                        //-                     </div>
                                        //-             </p>
                                        //-             <h5 style="text-align:center;">Polarization</h5>
                                        //-             <p>
                                        //-                 </div>
                                        //-                 <div class="column one-fourth">
                                        //-                     <div class="image_frame image_item no_link scale-with-grid aligncenter no_border">
                                        //-                         <div class="image_wrapper"><img class="scale-with-grid" src="/images/optics-single-priduct-icon-4.png" />
                                        //-                         </div>
                                        //-                     </div>
                                        //-             </p>
                                        //-             <h5 style="text-align:center;">UV protection</h5>
                                        //-             <p>
                                        //-                 </div>
                                        //-                 <div class="column one-fourth">
                                        //-                     <div class="image_frame image_item no_link scale-with-grid aligncenter no_border">
                                        //-                         <div class="image_wrapper"><img class="scale-with-grid" src="/images/optics-single-priduct-icon-2.png" />
                                        //-                         </div>
                                        //-                     </div>
                                        //-             </p>
                                        //-             <h5 style="text-align:center;">UV protection</h5>
                                        //-             <p>
                                        //-                 </div>
                                        //-                 </div>
                                        //-                 <hr style="margin:0 auto 50px;" />
                                        //-             </p>
                                        //-             <div class="column one">
                                        //-                 <div class="column one-second">
                                        //-                     <div class="image_frame image_item no_link scale-with-grid alignnone no_border">
                                        //-                         <div class="image_wrapper"><img class="scale-with-grid" src="/images/optics-single-product-glasses.jpg" />
                                        //-                         </div>
                                        //-                     </div>
                                        //-                 </div>
                                        //-                 <div class="column one-second">
                                        //-                     </p>
                                        //-                     <div style="padding:5%;">
                                        //-                         <h3>Quantum pertore
                                        //-                             <br> venire por no</h3>
                                        //-                         <p>
                                        //-                             Lorem ipsum <strong>dolor sit amet</strong>, consectetur adipiscing elit, sed do eiusmod tempor incididspendisse ultrices gravida. Risus commodo viverra maecen.
                                        //-                         </p>
                                        //-                         <p>
                                        //-                             Nullam ante nibh, facilisis quis accumsan quis, pharetra sed magna. Mauris efficitur a ex et molestie. Cras faucibus viverra tortor, at condimentum leo convallis ac. Sed sit amet erat urna. Aliquam volutpat justo ut purus malesuada ultrices.
                                        //-                         </p>
                                        //-                         <p>
                                        //-                             <hr class="no_line" style="margin:0 auto 10px" />
                                        //-                             <a class="button  button_size_2" href="#"><span class="button_label">Contact us</span></a>
                                        //-                         </p>
                                        //-                     </div>
                                        //-                     <p>
                                        //-                 </div>
                                        //-             </div>

                                        //-         </div>
                                        //-     </div>

                                        //-     <section class="related products">

                                        //-         <h2>Related products</h2>

                                        //-         <div class="products_wrapper isotope_wrapper">
                                        //-             <ul class="products ">

                                        //-                 <li class="isotope-item product type-product post-96 status-publish first instock product_cat-for-him has-post-thumbnail shipping-taxable purchasable product-type-simple">

                                        //-                     <div class="image_frame scale-with-grid product-loop-thumb" ontouchstart="this.classList.toggle('hover');">
                                        //-                         <div class="image_wrapper">
                                        //-                             <a href="product.html">
                                        //-                                 <div class="mask"></div><img width="600" height="444" src="/images/optics-shop-product-4.jpg" class="scale-with-grid wp-post-image" alt="" loading="lazy" /></a>
                                        //-                             <div class="image_links double">
                                        //-                                 <a rel="nofollow" href="#" data-quantity="1" data-product_id="96" class="add_to_cart_button ajax_add_to_cart product_type_simple"><i class="icon-basket"></i></a><a class="link" href="product.html"><i class="icon-link"></i></a>
                                        //-                             </div>
                                        //-                         </div><a href="product.html"><span class="product-loading-icon added-cart"></span></a>
                                        //-                     </div>
                                        //-                     <div class="desc">

                                        //-                         <h4><a href="product.html">Phasellus id mi</a></h4>

                                        //-                         <span class="price"><span class="woocommerce-Price-amount amount">
                                        //- 							<bdi>
                                        //- 								<span class="woocommerce-Price-currencySymbol">&#36;</span>100.00
                                        //-                         </bdi>
                                        //-                         </span>
                                        //-                         </span>

                                        //-                     </div>

                                        //-                 </li>

                                        //-                 <li class="isotope-item product type-product post-89 status-publish instock product_cat-for-her product_cat-for-him has-post-thumbnail shipping-taxable purchasable product-type-simple">

                                        //-                     <div class="image_frame scale-with-grid product-loop-thumb" ontouchstart="this.classList.toggle('hover');">
                                        //-                         <div class="image_wrapper">
                                        //-                             <a href="product.html">
                                        //-                                 <div class="mask"></div><img width="600" height="444" src="/images/optics-shop-product-12-600x444.jpg" class="scale-with-grid wp-post-image" alt="" loading="lazy" /></a>
                                        //-                             <div class="image_links double">
                                        //-                                 <a rel="nofollow" href="#" data-quantity="1" data-product_id="89" class="add_to_cart_button ajax_add_to_cart product_type_simple"><i class="icon-basket"></i></a><a class="link" href="product.html"><i class="icon-link"></i></a>
                                        //-                             </div>
                                        //-                         </div><a href="product.html"><span class="product-loading-icon added-cart"></span></a>
                                        //-                     </div>
                                        //-                     <div class="desc">

                                        //-                         <h4><a href="product.html">Proin sed mauris</a></h4>

                                        //-                         <span class="price"><span class="woocommerce-Price-amount amount">
                                        //- 							<bdi>
                                        //- 								<span class="woocommerce-Price-currencySymbol">&#36;</span>222.00
                                        //-                         </bdi>
                                        //-                         </span>
                                        //-                         </span>

                                        //-                     </div>

                                        //-                 </li>

                                        //-                 <li class="isotope-item product type-product post-97 status-publish instock product_cat-for-her product_cat-for-him has-post-thumbnail shipping-taxable purchasable product-type-simple">

                                        //-                     <div class="image_frame scale-with-grid product-loop-thumb" ontouchstart="this.classList.toggle('hover');">
                                        //-                         <div class="image_wrapper">
                                        //-                             <a href="product.html">
                                        //-                                 <div class="mask"></div><img width="600" height="444" src="/images/optics-shop-product-1.jpg" class="scale-with-grid wp-post-image" alt="" loading="lazy" /></a>
                                        //-                             <div class="image_links double">
                                        //-                                 <a rel="nofollow" href="#" data-quantity="1" data-product_id="97" class="add_to_cart_button ajax_add_to_cart product_type_simple"><i class="icon-basket"></i></a><a class="link" href="product.html"><i class="icon-link"></i></a>
                                        //-                             </div>
                                        //-                         </div><a href="product.html"><span class="product-loading-icon added-cart"></span></a>
                                        //-                     </div>
                                        //-                     <div class="desc">

                                        //-                         <h4><a href="product.html">Pellentesque</a></h4>

                                        //-                         <span class="price"><span class="woocommerce-Price-amount amount">
                                        //- 							<bdi>
                                        //- 								<span class="woocommerce-Price-currencySymbol">&#36;</span>35.00
                                        //-                         </bdi>
                                        //-                         </span>
                                        //-                         </span>

                                        //-                     </div>

                                        //-                 </li>

                                        //-             </ul>
                                        //-         </div>
                                        //-     </section>

                                        //- </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
	import MainLayout from './../layouts/ecommerce'
    import ProductVariation from '@/components/products/ProductVariation'
    export default {
        name: 'Product',
        components: {
            MainLayout,
            ProductVariation
        },
        props: {
            slug: {
                required: true
            }
        },
		computed: {
			...mapGetters({
				auth: 'auth/auth'
			})
		},
        data(){
            return {
                product: null,
                form: {
                    variation: '',
                    quantity: 1
                }
            }
        },
        methods: {
            ...mapActions({
                store: 'cart/store'
            }),
            add(){
                this.store([{
                    id: this.form.variation.id, quantity: this.form.quantity
                }])

                Swal.fire('Excelente!', 'Agregamos el producto a tu carrito', 'success')

                this.form = {
                    id: '',
                    quantity: 1
                }
            }
        },
        watch: {
            'form.variation' (){
                this.form.quantity = 1
            }
        },
        mounted(){
            this.axios.get(`products/${this.slug}`).then(response => {
                this.product = response.data.data
                // if(this.product.images.length > 1){
                //     $(".s_Product_carousel").owlCarousel({
                //         items:1,
                //         autoplay:false,
                //         autoplayTimeout: 5000,
                //         loop:true,
                //         nav:false,
                //         dots:true
                //     });
                // }
            })
            
        },
    }
</script>