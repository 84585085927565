<template lang="pug">
    div.app-page-title
        div.page-title-wrapper
            div.page-title-heading
                div.page-title-icon
                    i.icon-gradient.bg-mean-fruit(:class="icon")
                div {{ title }}
                    div.page-title-subheading {{ subtitle }}
            div.page-title-actions(v-if="withActions")
                button.btn-shadow.mr-3.btn.btn-dark(type="button" data-toggle="tooltip" title="Example Tooltip" data-placement="bottom")
                    i.fa.fa-star
                div.d-inline-block.dropdown
                    button.btn-shadow.dropdown-toggle.btn.btn-info(type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false")
                        span.btn-icon-wrapper.pr-2.opacity-7
                            i.fa.fa-business-time.fa-w-20
                        template Buttons
                    div.dropdown-menu.dropdown-menu-right(tabindex="-1" role="menu" aria-hidden="true")
                        ul.nav.flex-column
                            li.nav-item
                                a.nav-link
                                    i.nav-link-icon.lnr-inbox
                                    span Inbox
                                    div.ml-auto.badge.badge-pill.badge-secondary 86
                            li.nav-item
                                a.nav-link
                                    i.nav-link-icon.lnr-book
                                    span Book
                                    div.ml-auto.badge.badge-pill.badge-danger 5
                            li.nav-item
                                a.nav-link
                                    i.nav-link-icon.lnr-picture
                                    span Picture
                            li.nav-item
                                a.nav-link(disabled)
                                    i.nav-link-icon.lnr-file-empty
                                    span File Disabled
</template>
<script>
export default {
    props: {
        title: {
            required: true
        },
        subtitle: {
            required: true
        },
        icon: {
            required: true
        },
        withActions: {
            required: true
        },
    }
}
</script>