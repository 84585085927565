<template lang="pug">
    div.app-sidebar.sidebar-shadow
        div.app-header__logo
            div.logo-src
            div.header__pane.ml-auto
                div
                    button.hamburger.close-sidebar-btn.hamburger--elastic(type="button" data-class="closed-sidebar")
                        span.hamburger-box
                            span.hamburger-inner
        div.app-header__mobile-menu
            div
                button.hamburger.hamburger--elastic.mobile-toggle-nav(type="button")
                    span.hamburger-box
                        span.hamburger-inner
        div.app-header__menu
            span
                button.btn-icon.btn-icon-only.btn.btn-primary.btn-sm.mobile-toggle-header-nav(type="button")
                   span.btn-icon-wrapper
                        i.fa.fa-ellipsis-v.fa-w-6
        div.scrollbar-sidebar
            div.app-sidebar__inner
                ul.vertical-nav-menu
                    li.app-sidebar__heading Menu
                    li
                        router-link(:to="{ name: 'products'}")
                            i.metismenu-icon.fas.fa-tags
                            template Catálogo de Productos
                    li
                        router-link(:to="{ name: 'banners'}")
                            i.metismenu-icon.fas.fa-images
                            template Banners
                    li
                        router-link(:to="{ name: 'admin-orders'}")
                            i.metismenu-icon.fas.fa-shopping-cart
                            template Órdenes
                    
</template>
<style lang="stylus">
</style>
<script>
    export default {
        name: 'IndexAdminSidebar',
        components: {
        }
    }
</script>