<template lang="pug">
    table.table.is-hoverable.is-fullwidth
        tbody
            tr(v-for="paymentMethod in paymentMethods" :key="paymentMethod.id" :class="{'bg-success': paymentMethod.id === selectedPaymentMethod.id}")
                td 
                     p
                        strong {{ paymentMethod.card_type }}
                        br
                        span {{ paymentMethod.last_four }}
                td
                    a.button.is-info(href="" @click.prevent="$emit('click', paymentMethod)") Seleccionar

</template>
<script>
    export default {
        props: {
            paymentMethods: {
                required: true,
                type: Array
            },
            selectedPaymentMethod: {
                required: true,
                type: Object
            }
        }
    }
</script>