<template lang="pug">
    div.app-header.header-shadow
        div.app-header__logo
            div.logo-src
            div.header__pane.ml-auto
                div
                    button.hamburger.close-sidebar-btn.hamburger--elastic(type="button" data-class="closed-sidebar")
                        span.hamburger-box
                            span.hamburger-inner
        div.app-header__mobile-menu
            div
                button.hamburger.hamburger--elastic.mobile-toggle-nav(type="button")
                    span.hamburger-box
                        span.hamburger-inner
        div.app-header__menu
            span
                button.btn-icon.btn-icon-only.btn.btn-primary.btn-sm.mobile-toggle-header-nav(type="button")
                    span.btn-icon-wrapper
                        i.fa.fa-ellipsis-v.fa-w-6
        div.app-header__content
            div.app-header-left
                div.search-wrapper
                    div.input-holder
                        input.search-input(type="text" placeholder="Type to search")
                        button.search-icon
                            span
                    button.close
                ul.header-megamenu.nav
                    li.nav-item
                        a.nav-link(href="javascript:void(0);" data-placement="bottom" rel="popover-focus" data-offset="300" data-toggle="popover-custom")
                            i.nav-link-icon.fa.fa-gift
                            template Módulos
                            i.fa.fa-angle-down.ml-2.opacity-5
                        megamenu-component
                    //- li.nav-item.btn-group
                    //-     a.nav-link(data-toggle="dropdown" aria-expanded="false")
                    //-         span.badge.badge-pill.badge-danger.ml-0.mr-2 4
                    //-         template Settings
                    //-         i.fa.fa-angle-down.ml-2.opacity-5
                    //-     titlemenu-component
                    //- li.nav-item.dropdown
                    //-     a.nav-link(data-toggle="dropdown" aria-expanded="false")
                    //-         i.fa.fa-cogs.nav-link-icon
                    //-         template Projects
                    //-         i.fa.fa-angle-down.ml-2.opacity-5
                    //-     richtitlemenu-component
            div.app-header-right
                div.header-dots
                    div.dropdown
                        //- button.p-0.mr-2.btn.btn-link(type="button" aria-haspopup="true" aria-expanded="false" data-toggle="dropdown")
                        //-     span.icon-wrapper.icon-wrapper-alt.rounded-circle
                        //-         span.icon-wrapper-bg.bg-primary
                        //-         i.fa.fa-shapes.text-primary
                        //- div.dropdown-menu-xl.rm-pointers.dropdown-menu.dropdown-menu-right(tabindex="-1" role="menu" aria-hidden="true")
                            //- div.dropdown-menu-header
                            //-     div.dropdown-menu-header-inner.bg-plum-plate
                            //-         div.menu-header-image(style="background-image: url('/assets/images/dropdown-header/abstract4.jpg');")
                            //-         div.menu-header-content.text-white
                            //-             h5.menu-header-title Grid Dashboard
                            //-             h6.menu-header-subtitle Easy grid navigation inside dropdowns
                            //-     gridmenu-component
                    div.dropdown
                        //- button.p-0.mr-2.btn.btn-link(type="button" aria-haspopup="true" aria-expanded="false" data-toggle="dropdown")
                        //-     span.icon-wrapper.icon-wrapper-alt.rounded-circle
                        //-         span.icon-wrapper-bg.bg-danger
                        //-         i.text-danger.icon-anim-pulse.fa.fa-bell
                        //-         span.badge.badge-dot.badge-dot-sm.badge-danger Notifications
                        //- notifications-component
                div.header-btn-lg.pr-0
                    div.widget-content.p-0
                        div.widget-content-wrapper
                            div.widget-content-left.header-user-info
                                div.widget-heading {{ auth.name }}
                                div.widget-subheading Administrador de ecommerce
                            div.widget-content-left.ml-3
                                div.btn-group
                                    a.nav-link.p-0.btn(data-toggle="dropdown" aria-haspopup="true" aria-expanded="false")
                                        img.rounded-circle(width="42" src="/assets/images/avatars/1.jpg" alt="")
                                        i.fa.fa-angle-down.ml-2.opacity-8
                                    div.rm-pointers.dropdown-menu-lg.dropdown-menu.dropdown-menu-right(tabindex="-1" role="menu" aria-hidden="true")
                                        div.dropdown-menu-header
                                            div.dropdown-menu-header-inner.bg-info
                                                div.menu-header-image.opacity-2(style="background-image: url('/assets/images/dropdown-header/city3.jpg');")
                                                div.menu-header-content.text-left
                                                    div.widget-content.p-0
                                                        div.widget-content-wrapper
                                                            div.widget-content-left.mr-3
                                                                img.rounded-circle(width="42" src="/assets/images/avatars/1.jpg" alt="")
                                                            div.widget-content-left
                                                                div.widget-heading {{ auth.name }}
                                                                //- div.widget-subheading.opacity-8 A short profile description
                                                            div.widget-content-right.mr-2
                                                                button.btn-pill.btn-shadow.btn-shine.btn.btn-focus(@click.prevent="logout") Logout
                                        //- div.scroll-area-xs(style="height: 150px;")
                                        //-     div.scrollbar-container.ps
                                        //-         ul.nav.flex-column
                                        //-             li.nav-item-header.nav-item Activity
                                        //-             li.nav-item
                                        //-                 a.nav-link(href="javascript:void(0);") Chat
                                        //-                     div.ml-auto.badge.badge-pill.badge-info 8
                                        //-             li.nav-item
                                        //-                 a.nav-link(href="javascript:void(0);") Recover Password
                                        //-             li.nav-item-header.nav-item My Account
                                        //-             li.nav-item
                                        //-                 a.nav-link(href="javascript:void(0);") Settings
                                        //-                     div.ml-auto.badge.badge-success New
                                        //-             li.nav-item
                                        //-                 a.nav-link(href="javascript:void(0);") Messages
                                        //-                     div.ml-auto.badge.badge-warning 512
                                        //-             li.nav-item
                                        //-                 a.nav-link(href="javascript:void(0);") Logs
                                        //- ul.nav.flex-column
                                        //-     li.nav-item-divider.mb-0.nav-item
                                        //- div.grid-menu.grid-menu-2col
                                        //-     div.no-gutters.row
                                        //-         div.col-sm-6
                                        //-             button.btn-icon-vertical.btn-transition.btn-transition-alt.pt-2.pb-2.btn.btn-outline-warning
                                        //-                 i.fa.fa-envelope.icon-gradient.bg-amy-crisp.btn-icon-wrapper.mb-2
                                        //-                 template Message Inbox
                                        //-         div.col-sm-6
                                        //-             button.btn-icon-vertical.btn-transition.btn-transition-alt.pt-2.pb-2.btn.btn-outline-danger
                                        //-                 i.fa.fa-ticket-alt.icon-gradient.bg-love-kiss.btn-icon-wrapper.mb-2
                                        //-                 b Support Tickets
                                        //- ul.nav.flex-column
                                        //-     li.nav-item-divider.nav-item
                                        //-     li.nav-item-btn.text-center.nav-item
                                        //-         button.btn-wide.btn.btn-primary.btn-sm Open Messages
                            //- div.widget-content-right.header-user-info.ml-3
                            //-     button.btn-shadow.p-1.btn.btn-primary.btn-sm.show-toastr-example(type="button")
                            //-         i.fa.text-white.fa-calendar.pr-1.pl-1
                div.header-btn-lg
                    button.hamburger.hamburger--elastic.open-right-drawer(type="button")
                        span.hamburger-box
                            span.hamburger-inner
</template>
<style lang="stylus">
</style>
<script>import { mapActions, mapGetters } from 'vuex'
    import GridmenuComponent from './headerComponents/gridmenuComponent'
    import MegamenuComponent from './headerComponents/megamenuComponent'
    import NotificationsComponent from './headerComponents/notificationsComponent'
    import RichtitlemenuComponent from './headerComponents/richtitlemenuComponent'
    import TitlemenuComponent from './headerComponents/titlemenuComponent'
    export default {
        name: "Header",
        components: {
            GridmenuComponent,
            MegamenuComponent,
            NotificationsComponent,
            TitlemenuComponent,
            RichtitlemenuComponent,
        },
        methods: {
            ...mapActions({
                login: 'auth/login',
                logout: 'logout'
            })
        },
        computed: {
            ...mapGetters({
                auth: 'auth/auth',
            })
        },
        mounted(){
            this.login()
        }
    }
</script>