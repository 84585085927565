<template lang="pug">
    admin-layout
        template(slot="sidebar")
            sidebar
        template(slot="adminbody")
            admin-body
                template(slot="title")
                    page-title-component(title="Productos" subtitle="Catálogo de productos" icon="fa fa-home" :withActions="false")
                template(slot="content")
                    div.mb-3.card
                        div.card-header-tab.card-header
                            div.card-header-title.font-size-lg.text-capitalize.font-weight-normal
                                i.header-icon.lnr-charts.icon-gradient.bg-happy-green.fas.fa-box
                                template Edición de producto
                        div.card-body
                            div.no-gutters.row
                                .col-md-6.col-sm-12
                                    .container
                                        .form-group
                                            label Nombre de producto
                                            input.form-control.form-control-line(type="text" placeholder="Nombre de Producto" v-model="product.name")
                                        .form-group
                                            label URL producto
                                            input.form-control.form-control-line(type="text" disabled placeholder="nombre-de-producto" v-model="product.slug")
                                        .form-group
                                            label Precio base
                                            input.form-control.form-control-line(type="number" placeholder="500.00" step="any" v-model="product.price")
                                        .form-group
                                            label Descripción
                                            textarea.form-control.form-control-line(type="type" placeholder="Descripción del producto" v-model="product.description")
                                .col-md-6.col-sm-12
                                    .container
                                        CategorySelect(v-model="category" :categories="categories")
                                        .form-group
                                            ul
                                                li(v-for="(category, index) in product.categories" v-if="product.categories.length") {{ category.name }}
                                                    a.fa.fa-trash.ml-3.text-danger(href="#" @click.prevent="removeFromDB(index, product.categories, category)"  v-tooltip="'Eliminar categoría'")
                                            ul(v-if="product.newCategories")
                                                li(v-for="(category, index) in product.newCategories" ) {{ category.name }}
                                                    a.fa.fa-trash.ml-3.text-danger(href="#" @click.prevent="removeFromArray(index, product.categories)"  v-tooltip="'Eliminar categoría'")
                                        .form-group
                                            label Variación de productos
                                            .row
                                                .col-sm-12
                                                    select.form-control.form-control-line(v-model="variationType.productVariation")
                                                        option(value="null") Selecciona un tipo
                                                        option(v-for="type in variationTypes" :key="type.id" :value="type") {{ type.name }}
                                                .col-sm-12
                                                    input.form-control.form-control-line(v-model="variationType.price" type="number" placeholder="Precio")
                                                .col-sm-12
                                                    input.form-control.form-control-line(v-model="variationType.name" placeholder="Nombre. ej. Negro, Grande, Mediana")
                                                .col-sm-12
                                                    .btn.btn-link.float-right(@click.prevent="addVariation" v-if="action == 'add'" v-tooltip="'Agregar variación'") Agregar
                                                    .btn.btn-link.float-right(@click.prevent="setVariation" v-if="action == 'edit'" v-tooltip="'Editar variación'") Editar
                                        .form-group
                                            ul(v-for="(variations, type) in product.variations" :key="type" :type="type")
                                                strong {{ type }}
                                                li(v-for="(variation, index) in variations") {{ variation.name }}
                                                    .badge.badge-primary.ml-2(v-if="variation.price_varies") {{ variation.price }}
                                                    a.fa.fa-edit.ml-3(href="#" @click.prevent="editVariation(variation)" v-tooltip="'Editar variación'")
                                                    a.fa.fa-trash.text-danger.ml-3(href="#" @click.prevent="removeVariation(variation, variations, index)" v-tooltip="'Eliminar variación'")
                                            ul(v-if="product.newVariations.length")
                                                strong Nuevas variaciones
                                                li(v-for="(type, index) in product.newVariations") {{ type.productVariation.name }} - {{ type.name }}
                                                    span.badge.badge-primary.ml-1(v-if="type.price")  ${{ type.price | currency }}
                                                    a.fa.fa-trash.ml-3.text-danger(href="#" @click.prevent="removeFromArray(index, product.variations)" v-tooltip="'Eliminar variación'")
                                .col-sm-12
                                    button.btn.btn-success.float-right(@click.prevent="store") Guardar
                                    router-link.btn.btn-link.text-danger.float-right(:to="{ name: 'products' }") Cancelar
</template>
<script>
    import CategorySelect from '@/components/products/CategorySelect'
    import AdminLayout from '@/layouts/main'
    import AdminBody from '@/components/Architecht/Components/body'
    import PageTitleComponent from '@/components/Architecht/Components/bodyComponents/pageTitle'
    import Sidebar from './../components/sidebar'
    export default {
        name: 'EditProduct',
        layout: 'admin',
        components: {
            CategorySelect,
            AdminLayout,
            AdminBody,
            PageTitleComponent,
            Sidebar
        },
        props: {
            slug: {
                required: true
            }
        },
        middleware: [
            'redirectIfGuest',
            'redirectIfNotAdmin'
        ],
        data() {
            return {
                action: 'add',
                category: {},
                categories: [],
                data: "",
                product: {
                    name: '',
                    categories: [],
                    newCategories: [],
                    newVariations: [],
                    variations: [],
                },                
                variationType: {
                    productVariation: null
                },
                variationTypes: [],
            }
        },
        watch: {
            'product.name' (name){
                this.product.slug = name.split(' ').join('-')
            },
            'category' (category){
                if(!this.findCategory(category.id))
                    this.product.categories.push(category)
            }
        },
        methods: {
            addCategory(category){
                if(!this.product.newCategories){
                    this.product.newCategories = []
                }
                this.product.newCategories.push(category)
            },
            addVariation(){
                if(!this.product.newVariations){
                    this.product.newVariations = []
                }

                if(!this.findVariation(this.variationType.productVariation.id, this.variationType.name)){
                    this.product.newVariations.push(this.variationType)
                    this.variationType = {}
                }
            },
            editVariation(variation){
                this.variationType = JSON.parse(JSON.stringify(variation))
                this.variationType.price = this.variationType.price.replace('MX$', '')
                this.variationType.price = this.variationType.price.replace(',', '')
                this.variationType.productVariation = this.findVariationId(variation.product_variation_type_id)
                this.variationType.price = parseFloat(this.variationType.price)
                this.action = 'edit'
            },
            findCategory(id){
                let category = this.product.categories.find(v => v.id == id)
                if (typeof category === 'undefined'){
                    if(this.product.newCategories){
                        category = this.product.newCategories.find(v => v.id == id)
                        if (typeof category === 'undefined'){
                            return null
                        }
                    }
                }

                return category
            },
            findVariation(id, name){
                let variation = this.product.newVariations.find(v => (v.productVariation.id == id && v.name == name))
                if (typeof variation === 'undefined'){
                    return null
                }

                return variation
            },
            findVariationId(id){
                let variation = this.variationTypes.find(v => v.id == id)
                if (typeof variation === 'undefined'){
                    return null
                }

                return variation
            },
            removeFromArray(index, array){
                array.splice(index, 1)
            },
            async removeVariation(variation, array, index){
                await this.axios.delete(`delete-variations/${variation.id}`)
                array.splice(index, 1)
            },
            async removeFromDB(index, array, element){
                await this.axios.delete(`product-categories/${this.product.id}/${element.id}`)
                array.splice(index, 1)
            },
            async setVariation(){
                await this.axios.patch(`variations/${this.variationType.id}`, this.variationType).then(response =>{
                    let variation = this.product.variations[this.variationType.type].findIndex(v => v.id == this.variationType.id)
                    this.product.variations[this.variationType.type][variation] = response.data.data
                    this.variationType = {productVariation: null}
                })

                this.$forceUpdate()
            },
            store(){
                this.axios.patch(`products/${this.product.id}`, this.product).then(response => {
                    this.$router.replace({
                        name: 'products'
                    })
                })
            }
        },
        async mounted(){
            this.axios.get(`show-product/${this.slug}`).then(response => {
                this.product = response.data.data
                this.product.price = this.product.price.replace('MX$', '')
                this.product.price = this.product.price.replace(',', '')
                this.product.price = Number(this.product.price)
                this.product.newCategories = []
                this.product.newVariations = []

            })
            this.axios.get(`all-variations`).then(response => {
               this.variationTypes = response.data
            })
            this.axios.get(`get-categories`).then(response => {
               this.categories = response.data.data
            })
        }
    }
</script>