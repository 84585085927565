<template lang="pug">
    article.message
        .message-body
            h2.title Metodos de pago
            .row
                .col-sm-12
                    template(v-if="selecting")
                        PaymentMethodSelector(:paymentMethods="paymentMethods" :selectedPaymentMethod="selectedPaymentMethod" @click="switchPaymentMethod")
                    template(v-else-if="creating")
                        PaymentMethodCreator(@cancel="creating = false" @added="created")
                    template(v-else)
                        .row
                            .col-sm-8
                                template(v-if="selectedPaymentMethod")
                                    span Tarjeta:
                                        strong  {{ selectedPaymentMethod.card_type }}
                                    br
                                    span Últimos 4 dígitos:
                                        strong  {{ selectedPaymentMethod.last_four }}
                            .col-sm-4
                                p.control
                                    a.gray_btn(href="" @click.prevent="selecting = true" v-if="paymentMethods.length") Cambiar Método de Pago
                                p.control
                                    a.primary-btn(href="" @click.prevent="creating = true") Agregar Método de Pago
</template>
<script>
    import PaymentMethodSelector from './PaymentMethodSelector'
    import PaymentMethodCreator from './PaymentMethodCreator'
    export default {    
        components:{
            PaymentMethodCreator,
            PaymentMethodSelector,
        },
        data() {
            return {
                creating: false,
                selecting: false,
                localPaymentMethod: this.paymentMethods,
                selectedPaymentMethod: null
            }
        },
        props: {
            paymentMethods: {
                requiered: true,
                type: Array
            }
        },
        computed: {
            defaultPaymentMethod(){
                return this.paymentMethods.find(a => a.default == true)
            }
        },
        watch: {
            selectedPaymentMethod(PaymentMethod){
                this.selecting = false
                this.$emit('input', PaymentMethod.id)
            }
        },
        methods: {
            created(PaymentMethod){
                this.paymentMethods.push(PaymentMethod)
                this.creating = false
                this.switchPaymentMethod(PaymentMethod)
            },
            switchPaymentMethod(PaymentMethod){
                this.selectedPaymentMethod = PaymentMethod
            }
        },
        mounted(){
             setTimeout(() => {
                if(this.paymentMethods.length){
                    this.switchPaymentMethod(this.defaultPaymentMethod)
                }
             },500)
        }
    }
</script>