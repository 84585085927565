<template lang="pug">
    main-layout
        template(slot="sidebar")
            sidebar
        template(slot="adminbody")
            admin-body
                template(slot="title")
                    page-title-component(title="Órdenes" subtitle="Órdenes recibidas" icon="fa fa-shopping-cart" :withActions="false")
                template(slot="buttons")
                    li.nav-item
                        button.nav-link(role="tab" type="button" @click="getOrders")
                            span Refrescar
                template(slot="content")
                    div.mb-3.card
                        div.card-header-tab.card-header
                            div.card-header-title.font-size-lg.text-capitalize.font-weight-normal
                                i.header-icon.lnr-charts.icon-gradient.bg-happy-green
                                template Órdenes
                        div.no-gutters.row
                            div.col-sm-12
                                div#table.col-xs-12.table-responsive.table-bordered.table-hover.table-striped
                                    table-component(:columns="columns" :data="orders"  @filter="filterData" :meta="meta" :params="params")
                                        template(v-slot="{ row }")
                                            tr
                                                td.text-center {{ row.id }}
                                                td.text-center {{ row.user.name }}
                                                td.text-center {{ row.address }}
                                                td.text-center {{ row.status }}
                                                td.text-center 
                                                    a.fa.fa-file-pdf(v-if="row.prescription" :href="'//delnorte.dconsultores.com/' + row.prescription.url" target="blank")
                                                    span(v-else) Sin receta
                                                td.text-center {{ row.subtotal }}
                                                td.text-center {{ row.total }}
                                                td.text-center
                                                    a.fa.fa-redo-alt.mr-2(href="#" @click.prevent="processOrder(row)" v-if="row.status == 'pending'")
                                                    router-link.fa.fa-search.mr-2(:to="{ name: 'admin-order', params: { id: row.id } }" v-tooltip="'Ver detalle de orden'")
                                                td
                                                    a.fa.fa-trash.text-danger.mr-2(v-tooltip="'Eliminar orden'")
        template(slot="right_bar")
            div.position-relative.form-group
                label(for="code") Clave
                input.form-control(name="code" id="code" type="text" v-model="params.code")
            div.position-relative.form-group
                label(for="name") Nombre
                input.form-control(name="name" id="name" type="text" v-model="params.name")
            div.position-relative.form-group
                button.btn.btn-primary.mr-1(type="button" @click="getOrders") Aplicar
                button.btn.btn-danger(type="button" @click="clearFilter") Quitar</template>
<script>
    import MainLayout from '@/layouts/main'
    import AdminBody from '@/components/Architecht/Components/body'
    import PageTitleComponent from '@/components/Architecht/Components/bodyComponents/pageTitle'
    import ModalComponent from '@/components/Architecht/Components/Modals/ModalComponent'
    import Sidebar from '@/pages/admin/components/sidebar'
    import vSelect from 'vue-select'
    import 'vue-select/dist/vue-select.css'
    export default {
        name: 'OrdersAdmin',
        middleware: [
            'redirectIfGuest',
            'redirectIfNotAdmin'
        ],
        data(){
            return {
                columns: [
                    { label: 'Orden', field: 'id', sortable: true },
                    { label: 'Cliente', field: 'name', sortable: true },
                    { label: 'Dirección de envío', field: 'price', sortable: true },
                    { label: 'Estatus', field: 'stock_count', sortable: true },
                    { label: 'Receta', field: 'prescription', sortable: true },
                    { label: 'Subtotal', field: '', sortable: false },
                    { label: 'Total', field: '', sortable: false },
                    { label: 'Acciones', field: '', sortable: false },
                    { label: '', field: '', sortable: false },
                ],
                formstate: {},
                meta: {
                    last_page: 1
                },
                orders:[],
                params: {},
                product: '',
                query: {},
            }
        },
        layout: 'admin',
        watchQuery: true,
        async mounted(){
            await this.axios.get(`admin-orders`).then(response => {
                this.orders = response.data.data,
                this.meta = response.data.meta
            })
        },
        methods: {
            filterAction(){
                this.$router.replace({
                    query: this.query
                })
            },
            openModal() {
                this.editMode = false
                this.apiUrl = '/api/states'
                $("#modalComponent").modal('show')
            },

            async getCountries() {
                await axios.get('/api/catalog/countries', { params: this.params })
                    .then( response => {
                        this.countries = response.data.data
                    })
                    .catch( error => {
                        this.catchError(error)
                    })
            },
            async getCategories(){
                await this.axios.get(`categories`, { params: this.params }).then(response => {
                    this.categories = response.data.data
                })
            },
            async getOrders() {
                await this.axios.get('/admin-orders', { params: this.params })
                    .then( response => {
                        this.orders = response.data.data
                        this.meta = response.data.meta
                        this.stopLoading()
                    })
                    .catch( error => {
                        this.catchError(error)
                    })
            },

            async filterData(e){
                this.params = {
                    ...e
                }
                await this.getOrders()
            },

            async clearFilter() {
                this.params = {}
                await this.getOrders()
            },
            async processOrder(row) {
                this.editMode = true
                this.apiUrl = '/orders/'
                await this.axios.put('/orders/' + row.id)
                .then( response => {
                    this.getOrders()
                }).catch( error => {
                    this.catchError(error)
                })
                
            },
            async remove(row) {
                await this.deleteData('/api/states/' + row.id, 0, 'AfterChangeData', 'Espere por favor...', 'Exito')
            },
        },
        props: {},
        components: {
            AdminBody,
            MainLayout,
            PageTitleComponent,
            ModalComponent,
            vSelect,
            Sidebar,
        },
    }
</script>