<template lang="pug">
    main-layout
        template(slot="sidebar")
            sidebar
        template(slot="adminbody")
            admin-body
                template(slot="title")
                    page-title-component(title="Banners" subtitle="Catálogo de Banners" icon="fa fa-images" :withActions="false")
                template(slot="buttons")
                    li.nav-item
                        a.nav-link.active(role="tab" type="button" @click.prevent="openModal")
                            span Agregar
                    li.nav-item
                        button.nav-link.active(role="tab" type="button" @click="getBanners")
                            span Refrescar
                template(slot="content")
                    div.mb-3.card
                        //- div.card-header-tab.card-header
                            //- div.card-header-title.font-size-lg.text-capitalize.font-weight-normal
                            //-     i.header-icon.lnr-charts.icon-gradient.bg-happy-green
                            //-     template banners
                        div.no-gutters.row
                            div.col-sm-12.pt-4
                                div#table.col-xs-12.table-responsive.table-bordered.table-hover.table-striped
                                    table-component(:columns="columns" :data="banners"  @filter="filterData" :meta="meta" :params="params")
                                        template(v-slot="{ row }")
                                            tr
                                                td {{ row.title }}
                                                td {{ row.subtitle }}
                                                td 
                                                    img.thumbail(:src="baseImageURL + row.image")
                                                td.text-center
                                                    a.fa.fa-trash.mr-2(@click.prevent="destroy(row)" v-tooltip="'Desactivar banner'")
                                                    a.fa.fa-power-off.mr-2(@click.prevent="deactivate(row)" :class="row.status ? 'text-danger' : 'text-primary'" v-tooltip="'Desactivar banner'")
                        div.text-center.d-block.p-3.card-footer
        template(slot="modal")
            div.modal.fade.bd-example-modal-lg#modalComponent(tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true")
                div.modal-dialog.modal-lg
                    div.modal-content
                        div.modal-header
                            h5.modal-title(id="modalTitle") Agregar Banner
                            button.close(type="button" data-dismiss="modal" aria-label="Close" @click.prevent="closeModal")
                                span(aria-hidden="true") &times;
                        form(@submit.prevent="store")
                            div.modal-body
                                div.position-relative.form-group
                                    label(for="title") Titulo
                                    input.form-control(name="title" id="title" type="text" v-model="model.title" required)
                                div.position-relative.form-group
                                    label(for="subtitle") Subitulo
                                    input.form-control(name="subtitle" id="subtitle" type="text" v-model="model.subtitle" required)
                                div.position-relative.form-group
                                    label(for="file") Archivo
                                    input.ml-2(name="file" id="file" type="file" @change="addFile")
                            div.modal-footer
                                    button.btn.btn-secondary(type="button" data-dismiss="modal" @click.prevent="closeModal") Cerrar
                                    button.btn.btn-primary(type="submit") Guardar
        template(slot="right_bar")
            div.position-relative.form-group
                label(for="code") Clave
                input.form-control(name="code" id="code" type="text" v-model="params.code")
            div.position-relative.form-group
                label(for="name") Nombre
                input.form-control(name="filter_name" id="filter_name" type="text" v-model="params.name")
            div.position-relative.form-group
                button.btn.btn-primary.mr-1(type="button" @click="getBanners") Aplicar
                button.btn.btn-danger(type="button" @click="clearFilter") Quitar
</template>
<style>
    .thumbail{
        width: 100px;
    }
</style>
<script>
    import MainLayout from './../../../layouts/main'
    import AdminBody from './../../../components/Architecht/Components/body'
    import PageTitleComponent from './../../../components/Architecht/Components/bodyComponents/pageTitle'
    import ModalComponent from './../../../components/Architecht/Components/Modals/ModalComponent'
    import Sidebar from './../components/sidebar'
    import vSelect from 'vue-select'
    import 'vue-select/dist/vue-select.css'
    export default {
        components: {
            AdminBody,
            MainLayout,
            PageTitleComponent,
            ModalComponent,
            vSelect,
            Sidebar,
        },
        data() {
            return{
                apiUrl: '/banners',
                baseImageURL: window.baseImageURL,
                banners: [],
                columns: [
                    { label: 'Título de banner', field: 'title', sortable: true },
                    { label: 'Subtítulo', field: 'subtitle', sortable: true },
                    { label: 'Imagen', field: 'image', sortable: false },
                    { label: 'Acciones', field: '', sortable: false },
                ],
                editMode: false,
                formstate: {},
                model: {},
                meta: {
                    last_page: 1
                },
                params: {},
                parents: [],
            }
        },
        methods: {
            addFile(e){
                this.data = new FormData()
                this.data.append('file', e.target.files[0])
            },
            destroy(banner){
                this.axios.delete(`banners/${banner.id}`).then(response => {
                    this.getBanners()
                })
            },
            deactivate(banner){
                if(banner.status){
                    Swal.fire({
                        title: 'Cuidado!',
                        text: 'Estas seguro de realizar esta acción',
                        icon: 'warning',
                        confirmButtonText: 'Si, vamos a hacerlo',
                        showCancelButton: true,
                        confirmButtonColor: '#ff0000'
                    }).then((result) => {
                        if (result.value) {
                            this.setActive(banner)
                            Swal.fire(
                                'Desactivada!',
                                'El banner fue desactivado.',
                                'success'
                            )
                            this.getBanners()
                        }
                    })
                }else{
                    this.setActive(banner)
                    Swal.fire(
                        'Activada!',
                        'La banner fue activado.',
                        'success'
                    )
                }
            },
            async filterData(e){
                this.params = {
                    ...e
                }
                await this.getBanners()
            },

            async clearFilter() {
                this.params = {}
                await this.getBanners()
            },
            openModal() {
                this.editMode = false
                this.apiUrl = '/banners'
                this.parents = this.banners
                $("#modalComponent").modal('show')
            },
            setActive(banner){
                banner.status = !banner.status
                this.axios.patch(`banners/${banner.id}`, banner)
            },
            store(){
                this.openLoading('Espere por favor...')
                this.data.append('data', JSON.stringify(this.model))
                this.axios.post('banners', this.data).then(response => {
                    this.stopLoading()
                    this.banners.push(response.data)
                    $(".modal").modal('hide')
                }).catch(errors => {
                    Swal.fire(
                        'Error!',
                        errors.response.data.message,
                        'error'
                    )
                })
            },
            async getBanners(){
                await this.axios.get(`banners`, { params: this.params }).then(response => {
                    this.banners = response.data.data
                }).catch(errors => {
                    Swal.fire(
                        'Error!',
                        errors.response.data.message,
                        'error'
                    )
                })
            },
        },
        async mounted() {
            this.openLoading('Espere por favor...')
            await this.getBanners()
            this.stopLoading()

            Fire.$on('AfterChangeData', async () => {
                this.openLoading('Espere por favor...')
                this.model = { name: '' }
                await this.getBanners()
                this.stopLoading()
            })
        },
        beforeDestroy() {
            Fire.$off('AfterChangeData')
        }
    }
</script>
