<template lang="pug">
    div.modal.fade.bd-example-modal-lg#modalComponent(tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true")
        div.modal-dialog.modal-lg
            div.modal-content
                div.modal-header
                    h5.modal-title(id="modalTitle") {{ title }}
                    button.close(type="button" data-dismiss="modal" aria-label="Close"  @click.prevent="closeModal")
                        span(aria-hidden="true") &times;
                vue-form(ref="form_data" :state="formstate" @submit.prevent="editMode ? update() : create()")
                    div.modal-body
                        slot(name="modalContent")
                    div.modal-footer
                        button.btn.btn-secondary(type="button" data-dismiss="modal" @click.prevent="closeModal") Cerrar
                        button.btn.btn-primary(type="submit") Guardar
</template>
<script>
export default {
    name: 'ModalComponent',
    data() {
        return{
            formstate: {},
        }
    },
    emits: [
        'success'
    ], 
    methods: {
        async create() {
            if (this.formstate.$invalid) {
                return;
            } else {
                await this.saveData(this.apiUrl, this.model, '', 'AfterChangeData', 1, 0, 1, null, 'Espere por favor...', 'Exito', 1)
            }
        },

        async update() {
            if (this.formstate.$invalid) {
                return;
            } else {
                await this.updateData(this.apiUrl + this.model.id, this.model, '', 'AfterChangeData', 1, 0, 1, null, 'Espere por favor...', 'Exito', 1)
            }
        }
    },
    props: {
        title: {
            required: true
        },
        editMode: {
            required: true,
            default: false
        },
        model: {
            required: true
        },
        apiUrl: {
            required: true
        },
    },
    mounted() {
        
    }
}
</script>