<template lang="pug">
    div.app-sidebar.sidebar-shadow
        div.app-header__logo
            div.logo-src
            div.header__pane.ml-auto
                div
                    button.hamburger.close-sidebar-btn.hamburger--elastic(type="button" data-class="closed-sidebar")
                        span.hamburger-box
                            span.hamburger-inner
        div.app-header__mobile-menu
            div
                button.hamburger.hamburger--elastic.mobile-toggle-nav(type="button")
                    span.hamburger-box
                        span.hamburger-inner
        div.app-header__menu
            span
                button.btn-icon.btn-icon-only.btn.btn-primary.btn-sm.mobile-toggle-header-nav(type="button")
                   span.btn-icon-wrapper
                        i.fa.fa-ellipsis-v.fa-w-6
        div.scrollbar-sidebar
            div.app-sidebar__inner
                ul.vertical-nav-menu
                    li.app-sidebar__heading Menu
                    li
                        router-link(:to="{name: 'admin'} ")
                            i.metismenu-icon.fa.fa-home
                            template  Inicio
                    li.app-sidebar__heading Catálogos de sistema
                    li
                        router-link(:to="{name: 'categories'} ")
                            i.metismenu-icon.fas.fa-tags
                            template  Categorías
                    li
                        router-link(:to="{name: 'products'} ")
                            i.metismenu-icon.fas.fa-shopping-basket
                            template  Productos
                    li
                        router-link(:to="{name: 'shipping-methods'} ")
                            i.metismenu-icon.fas.fa-truck-loading
                            template  Métodos de envío
                    li
                        router-link(:to="{name: 'variations'} ")
                            i.metismenu-icon.fas.fa-exchange-alt
                            template  Variaciones de productos
</template>
<style lang="stylus">
</style>
<script>
    export default {
        name: 'side-bar',
        components: {
        }
    }
</script>