<template lang="pug">
    div.app-main__outer
        div.app-main__inner
            slot(name="title")
            ul.body-tabs.body-tabs-layout.tabs-animated.body-tabs-animated.nav
                slot(name="buttons")
            div.tabs-animation
                slot(name="content")
</template>
<style lang="stylus">
</style>
<script>
    export default {
        components: {
        }
    }
</script>