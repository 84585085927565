<template lang="pug">
    main-layout
        template(slot="sidebar")
            sidebar
        template(slot="adminbody")
            admin-body
                template(slot="title")
                    page-title-component(title="Productos" subtitle="Catálogo de productos" icon="fa fa-globe-americas" :withActions="false")
                template(slot="buttons")
                    li.nav-item
                        router-link.nav-link.active(role="tab" type="button" :to="{name:'add-products'}")
                            span Agregar
                    li.nav-item
                        button.nav-link.active(role="tab" type="button" @click="getProducts")
                            span Refrescar
                template(slot="content")
                    div.mb-3.card
                        div.card-header-tab.card-header
                            div.card-header-title.font-size-lg.text-capitalize.font-weight-normal
                                i.header-icon.lnr-charts.icon-gradient.bg-happy-green
                                template Productos
                        div.no-gutters.row
                            div.col-sm-12
                                div#table.col-xs-12.table-responsive.table-bordered.table-hover.table-striped
                                    table-component(:columns="columns" :data="products"  @filter="filterData" :meta="meta" :params="params")
                                        template(v-slot="{ row }")
                                            tr
                                                td {{ row.name }}
                                                td.text-center {{ row.price }}
                                                td.text-center {{ row.stock_count }}
                                                td.text-center
                                                    router-link.fa.fa-edit.mr-2(:to="{ name: 'edit-products', params: { slug: row.slug }}" v-tooltip="'Editar producto'")
                                                    router-link.fa.fa-images.mr-2(:to="{ name: 'images-products', params: { slug: row.slug }}" v-tooltip="'Administrar imagenes'")
                                                    router-link.fa.fa-boxes.mr-2(:to="{ name: 'stocks-products', params: { slug: row.slug }}" v-tooltip="'Administrar inventario'")
                                                    a.fa.fa-store-alt.mr-2(@click.prevent="addAdjust(row)" v-tooltip="'Generar venta en tienda'")
                                                    //- a.fa.fa-th-large.mr-2(@click.prevent="addBanner(row, 'main')" v-tooltip="'Agregar a banner principal'" :class="row.banner ? 'yellow' : '' ")
                                                    //- a.fa.fa-star.mr-2(@click.prevent="addBanner(row.id, 'favorite')" v-tooltip="'Agregar a favoritos'")
                                                    a.fa.fa-clock.mr-2(@click.prevent="addBanner(row, 'last')" v-tooltip="'Agregar a últimos productos'" :class="row.last ? 'yellow' : '' ")
                                                td
                                                    a.fa.fa-trash.text-danger.mr-2(v-tooltip="'Eliminar producto'" @click.prevent="removeProduct(row)")
                        div.text-center.d-block.p-3.card-footer
        template(slot="modal")
            div.modal.fade.bd-example-modal-lg#adjustComponent(tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true")
                div.modal-dialog.modal-lg
                    div.modal-content
                        div.modal-header
                            h5.modal-title(id="modalTitle") Venta en tienda
                            button.close(type="button" data-dismiss="modal" aria-label="Close" @click.prevent="closeModal")
                                span(aria-hidden="true") &times;
                        form(@submit.prevent="store()")
                            div.modal-body
                                ProductVariation(v-for="(variations, type) in product.variations" 
                                    :key="type" 
                                    :type="type" 
                                    :variations="variations" 
                                    v-model="form.variation")
                                .field.has-addons(v-if="form.variation")
                                    label(for="quantity") Cantidad:
                                    select.form-control.p-2(name="quantity" v-model="form.quantity")
                                        option(v-for="x in parseInt(form.variation.stock_count)" :key="x" :value="x") {{ x }}
                                button.btn.btn-text(@click.prevent="addProduct" v-if="form.quantity != '' && form.variation != ''") Agregar a lista
                                div.container(v-if="form.products.length")
                                    strong Productos agregados
                                    li(v-for="product in form.products") {{ product.product_variation_id.name }} - {{ product.quantity }}
                            div.modal-footer
                                button.btn.btn-secondary(type="button" data-dismiss="modal" @click.prevent="closeModal") Cerrar
                                button.btn.btn-primary(type="submit") Guardar
        template(slot="right_bar")
            div.position-relative.form-group
                label(for="name") Nombre
                input.form-control(name="name" id="name" type="text" v-model="params.name")
            div.position-relative.form-group
                label(for="category") Categoría
                v-select.mr-2(:options="categories" label="name" v-model="params.category" :reduce="name => name.slug")
            div.position-relative.form-group
                button.btn.btn-primary.mr-1(type="button" @click="getProducts") Aplicar
                button.btn.btn-danger(type="button" @click="clearFilter") Quitar
</template>
<style>
    .yellow{
        color: yellowgreen !important
    }
</style>
<script>
    import MainLayout from './../../../layouts/main'
    import AdminBody from './../../../components/Architecht/Components/body'
    import PageTitleComponent from './../../../components/Architecht/Components/bodyComponents/pageTitle'
    import ModalComponent from './../../../components/Architecht/Components/Modals/ModalComponent'
    import Sidebar from './../components/sidebar'
    import ProductVariation from '@/components/products/ProductVariation'
    import vSelect from 'vue-select'
    import 'vue-select/dist/vue-select.css'
    export default {
        components: {
            AdminBody,
            MainLayout,
            PageTitleComponent,
            ModalComponent,
            ProductVariation,
            vSelect,
            Sidebar,
        },
        data() {
            return{
                editMode: false,
                categories: [],
                products: [],
                form: {
                    products: [],
                    product_variation_id: '',
                    quantity: ''
                },
                model: {
                    code: '',
                    name: '',
                    alternate_code: '',
                    country_id: '',
                },
                columns: [
                    { label: 'Nombre de producto', field: 'name', sortable: true },
                    { label: 'Precio base', field: 'price', sortable: false },
                    { label: 'Existencias', field: 'stock_count', sortable: false },
                    { label: 'Acciones', field: '', sortable: false },
                    { label: '', field: '', sortable: true },
                ],
                meta: {
                    last_page: 1
                },
                params: {},
                product: {},
                apiUrl: '/api/states'
            }
        },
        methods: {
            openModal() {
                this.editMode = false
                this.apiUrl = '/api/states'
                $("#modalComponent").modal('show')
            },
            addAdjust(product){
                this.axios.get('show-product/' + product.slug).then(response => {
                    this.product = response.data.data
                })
                $('#adjustComponent').modal('show')
            },
            addProduct(){
                if(this.form.products == undefined){
                    this.form.products = []
                }
                let find = this.form.products.find(e => e.product_variation_id == this.form.variation)
                if(find){
                    find.quantity += this.form.quantity
                }else{
                    this.form.products.push({
                        'product_variation_id': this.form.variation,
                        'quantity': this.form.quantity
                    })
                }
                this.form.variation = ''
                this.form.quantity = ''
            },
            async addBanner(product, type){
                let url = ""
                if(type == 'main')
                    url = 'banner-products'
                if(type == 'favorite')
                    url = 'favorite-products'
                if(type == 'last')
                    url = 'last-products'

                await this.axios.post(url, {product_id: product.id})
                    .then(response => {
                        if(response.data == 'success'){
                            Swal.fire(
                                'Guardado!',
                                'El producto se agregó correctamente.',
                                'success'
                            )

                            if(type == 'main')
                                product.banner = { product_id: product.id }
                            if(type == 'last')
                                product.last = { product_id: product.id }
                        }
                        if(response.data == 'deleted'){
                            Swal.fire(
                                'Guardado!',
                                'El producto se desactivó correctamente.',
                                'success'
                            )

                            if(type == 'main')
                                product.banner = null
                            if(type == 'last')
                                product.last = null
                        }
                    }).catch(errors => {
                        Swal.fire(
                            'Error!',
                            errors.response.data.message,
                            'error'
                        )
                    })
            },
            async getCountries() {
                await axios.get('/api/catalog/countries', { params: this.params })
                .then( response => {
                    this.countries = response.data.data
                })
                .catch( error => {
                    this.catchError(error)
                })
            },
            async getCategories(){
                await this.axios.get(`categories`, { params: this.params }).then(response => {
                    this.categories = response.data.data
                })
            },
            async getProducts() {
                await this.axios.get('/products', { params: this.params })
                .then( response => {
                    this.products = response.data.data
                    this.meta = response.data.meta
                    this.stopLoading()
                })
                .catch( error => {
                    this.catchError(error)
                })
            },

            async filterData(e){
                this.params = {
                    ...e
                }
                await this.getProducts()
            },

            async clearFilter() {
                this.params = {}
                await this.getProducts()
            },
            async edit(row) {
                this.editMode = true
                this.apiUrl = '/api/states/'
                await this.axios.get('/api/states/' + row.id)
                .then( response => {
                    this.model = response.data.data
                    $("#modalComponent").modal('show')
                })
                .catch( error => {
                    this.catchError(error)
                })
                
            },
            async removeProduct(product){
                Swal.fire({
                    title: 'Cuidado!',
                    text: 'Estas seguro de realizar esta acción',
                    icon: 'warning',
                    confirmButtonText: 'Si, vamos a hacerlo',
                    showCancelButton: true,
                    confirmButtonColor: '#ff0000'
                }).then((result) => {
                    if (result.value) {
                        this.axios.delete(`products/${product.id}`)
                        Swal.fire(
                            'Eliminada!',
                            'El producto fue eliminado.',
                            'success'
                        )
                    }
                })
            },
            async store(){
                Swal.fire({
                    title: 'Cuidado!',
                    text: 'Estas seguro de realizar esta acción',
                    icon: 'warning',
                    confirmButtonText: 'Si, vamos a hacerlo',
                    showCancelButton: true,
                    confirmButtonColor: '#ff0000'
                }).then((result) => {
                    if (result.value) {
                        this.axios.post(`store-internal`, this.form).then(response => {
                            Swal.fire(
                                'Agregada!',
                                'La venta en tienda fue agregada.',
                                'success'
                            )
                            this.form.products = []
                            this.getProducts()
                            $('.modal').modal('hide')
                        }).catch(errors => {
                            Swal.fire(
                                'Error!',
                                'Ha ocurrido un error.',
                                'danger'
                            )
                        })
                    }
                })
            }
        },
        async mounted() {
            this.openLoading('Espere por favor...')
            await this.getProducts()
            await this.getCategories()

            Fire.$on('AfterChangeData', async () => {
                this.openLoading('Espere por favor...')
                await this.getProducts()
                this.stopLoading()
            })
        },
        beforeDestroy() {
            Fire.$off('AfterChangeData')
        }
    }
</script>
