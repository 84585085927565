<template lang="pug">
    main-layout
        template(slot="sidebar")
            sidebar
        template(slot="adminbody")
            admin-body
                template(slot="title")
                    page-title-component(title="Variaciones" subtitle="Catálogo de Variaciones" icon="fa fa-globe-americas" :withActions="false")
                template(slot="buttons")
                    li.nav-item
                        a.nav-link.active(role="tab" type="button" @click.prevent="openModal")
                            span Agregar
                    li.nav-item
                        button.nav-link.active(role="tab" type="button" @click="getVariations")
                            span Refrescar
                template(slot="content")
                    div.mb-3.card
                        //- div.card-header-tab.card-header
                            //- div.card-header-title.font-size-lg.text-capitalize.font-weight-normal
                            //-     i.header-icon.lnr-charts.icon-gradient.bg-happy-green
                            //-     template Variaciones
                        div.no-gutters.row
                            div.col-sm-12.pt-4
                                div#table.col-xs-12.table-responsive.table-bordered.table-hover.table-striped
                                    table-component(:columns="columns" :data="variations"  @filter="filterData" :meta="meta" :params="params")
                                        template(v-slot="{ row }")
                                            tr
                                                td {{ row.name }}
                                                td.text-center
                                                    a.fa.fa-trash.text-danger.mr-2(v-if="!row.products" @click.prevent="deleteVariation(row)" v-tooltip="'Eliminar variación'")
                        div.text-center.d-block.p-3.card-footer
        template(slot="modal")
            modal-component(title="Nueva variación" :editMode="editMode" :model="model" :apiUrl="apiUrl")
                template(slot="modalContent")
                    div.position-relative.form-group
                        validate(tag="div")
                            label(for="name") Nombre
                            input.form-control(name="name" id="name" type="text" v-model="model.name" required)
                            field-messages.text-danger(name="name" show="$invalid && $submitted")
                                div(slot="required") Este campo es requerido
        template(slot="right_bar")
            div.position-relative.form-group
                label(for="code") Clave
                input.form-control(name="code" id="code" type="text" v-model="params.code")
            div.position-relative.form-group
                label(for="name") Nombre
                input.form-control(name="filter_name" id="filter_name" type="text" v-model="params.name")
            div.position-relative.form-group
                button.btn.btn-primary.mr-1(type="button" @click="getVariations") Aplicar
                button.btn.btn-danger(type="button" @click="clearFilter") Quitar
</template>

<script>
    import MainLayout from './../../../layouts/main'
    import AdminBody from './../../../components/Architecht/Components/body'
    import PageTitleComponent from './../../../components/Architecht/Components/bodyComponents/pageTitle'
    import ModalComponent from './../../../components/Architecht/Components/Modals/ModalComponent'
    import Sidebar from './../components/sidebar'
    import vSelect from 'vue-select'
    import 'vue-select/dist/vue-select.css'
    export default {
        components: {
            AdminBody,
            MainLayout,
            PageTitleComponent,
            ModalComponent,
            vSelect,
            Sidebar,
        },
        data() {
            return{
                apiUrl: '/variations',
                variations: [],
                columns: [
                    { label: 'Nombre de categoría', field: 'name', sortable: true },
                    { label: 'Acciones', field: '', sortable: true },
                ],
                editMode: false,
                formstate: {},
                model: {},
                meta: {
                    last_page: 1
                },
                params: {},
                parents: [],
            }
        },
        watch: {},
        methods: {
            deleteVariation(variation){
                Swal.fire({
                    title: 'Cuidado!',
                    text: 'Estas seguro de realizar esta acción',
                    icon: 'warning',
                    confirmButtonText: 'Si, vamos a hacerlo',
                    showCancelButton: true,
                    confirmButtonColor: '#ff0000'
                }).then((result) => {
                    if (result.value) {
                        this.axios.delete(`variations/${variation.id}`).then(response => {
                            Swal.fire(
                                'Eliminada!',
                                'La variación fue eliminada.',
                                'success'
                            )
                            this.getVariations()
                        })
                    }
                })
            },
            async filterData(e){
                this.params = {
                    ...e
                }
                await this.getVariations()
            },

            async clearFilter() {
                this.params = {}
                await this.getVariations()
            },
            openModal() {
                this.editMode = false
                this.apiUrl = '/variations'
                $("#modalComponent").modal('show')
            },
            async getVariations(){
                await this.axios.get(`variations`, { params: this.params }).then(response => {
                    this.variations = response.data.data
                    this.meta = response.data.meta
                })
            },
        },
        async mounted() {
            this.openLoading('Espere por favor...')
            await this.getVariations()
            this.stopLoading()

            Fire.$on('AfterChangeData', async () => {
                this.openLoading('Espere por favor...')
                this.model = { name: '' }
                await this.getVariations()
                this.stopLoading()
            })
        },
        beforeDestroy() {
            Fire.$off('AfterChangeData')
        }
    }
</script>
